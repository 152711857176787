import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private auth: AuthenticationService) { } 

  public getOptions(): any {
    let headers: any = new HttpHeaders()
      .append('Content-Type', 'application/json; charset=utf-8')
    return { headers: headers, withCredentials: true };
  }

  public getFileOptions(): any {
    let headers: any = new HttpHeaders()
      .append('Content-Type', 'application/octet-stream')
    return { headers: headers, responseType: 'blob', withCredentials: true }
  }
}
