import { Costcenter } from "./costcenter";
import { DateService } from '../services/date.service';

export class EmploymentConditions {

    private _id: number;
    private _person_id: number;
    private _start_date: Date;
    private _end_date: Date;
    private _position_effective_date: Date;
    private _worker_type: string;
    private _weekly_hours: number;
    private _organisation: string;
    private _organisation_type: string;
    private _business_site: string;
    private _supervisor_id: string;
    private _vacation_in_days: number;
    private _is_active: number;
    private _compensation_grade_profile: string;
    private _collective_group: string;
    private _collective_agreement: string;
    private _salary_group: string;
    private _job_family_group: string;
    private _job_family: string;
    private _job_profile: string;
    private _costcenter_id: number;
    private _contract_type: string;
    private _costcenter: Costcenter;
    private _dirty: boolean = false;
    private dateService: DateService = new DateService();

    constructor(obj?: any) {
        try {
            this._id = obj.id;
            this._person_id = obj.person_id;
            this._start_date = new Date(obj.start_date);
            this._end_date = (obj.end_date) ? new Date(obj.end_date) : undefined;
            this._position_effective_date = new Date(obj.position_effective_date);
            this._worker_type = obj.worker_type;
            this._weekly_hours = obj.weekly_hours;
            this._organisation = obj.organisation;
            this._organisation_type = obj.organisation_type;
            this._business_site = obj.business_site;
            this._supervisor_id = obj.supervisor_id;
            this._vacation_in_days = obj.vacation_in_days;
            this._is_active = obj.is_active;
            this._compensation_grade_profile = obj.compensation_grade_profile;
            this._collective_group = obj.collective_group;
            this._collective_agreement = obj.collective_agreement;
            this._salary_group = obj.salary_group;
            this._job_family_group = obj.job_family_group;
            this._job_family = obj.job_family;
            this._job_profile = obj.job_profile;
            this._costcenter_id = obj.costcenter_id;
            this._contract_type = obj.contract_type;
            this._costcenter = new Costcenter(obj.costcenter);
        } catch (err) {
            return null;
        }
    }

    public toJSON(): Object {
        let start_date = (!this._start_date) ? null : this.dateService.format(this._start_date, 'yyyy-mm-dd');
        let end_date = (!this._end_date) ? null : this.dateService.format(this._end_date, 'yyyy-mm-dd');
        let costcenter_id = (this._costcenter) ? this._costcenter.id : this._costcenter_id;
        let costcenter = (this._costcenter) ? this._costcenter.costcenter : null;
        return {
            id: this._id,
            person_id: this._person_id,
            start_date: start_date,
            end_date: end_date,
            worker_type: this._worker_type,
            weekly_hours: this._weekly_hours,
            organisation: this._organisation,
            organisation_type: this._organisation_type,
            business_site: this._business_site,
            supervisor_id: this._supervisor_id,
            compensation_grade_profile: this._compensation_grade_profile,
            collective_group: this._collective_group,
            collective_agreement: this._collective_agreement,
            salary_group: this._salary_group,
            job_family_group: this._job_family_group,
            job_family: this._job_family,
            job_profile: this._job_profile,
            costcenter_id: costcenter_id,
            costcenter: costcenter,
            contract_type: this._contract_type
        }
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter person_id
     * @return {number}
     */
	public get person_id(): number {
		return this._person_id;
	}

    /**
     * Getter start_date
     * @return {Date}
     */
	public get start_date(): Date {
		return this._start_date;
	}

    /**
     * Getter end_date
     * @return {Date}
     */
	public get end_date(): Date {
		return this._end_date;
	}

    /**
     * Getter position_effective_date
     * @return {Date}
     */
	public get position_effective_date(): Date {
		return this._position_effective_date;
	}

    /**
     * Getter worker_type
     * @return {string}
     */
	public get worker_type(): string {
		return this._worker_type;
	}

    /**
     * Getter weekly_hours
     * @return {number}
     */
	public get weekly_hours(): number {
		return this._weekly_hours;
	}

    /**
     * Getter organisation
     * @return {string}
     */
	public get organisation(): string {
		return this._organisation;
	}

    /**
     * Getter organisation_type
     * @return {string}
     */
	public get organisation_type(): string {
		return this._organisation_type;
	}

    /**
     * Getter business_site
     * @return {string}
     */
	public get business_site(): string {
		return this._business_site;
	}

    /**
     * Getter supervisor_id
     * @return {string}
     */
	public get supervisor_id(): string {
		return this._supervisor_id;
	}

    /**
     * Getter vacation_in_days
     * @return {number}
     */
	public get vacation_in_days(): number {
		return this._vacation_in_days;
	}

    /**
     * Getter is_active
     * @return {number}
     */
	public get is_active(): number {
		return this._is_active;
	}

    /**
     * Getter compensation_grade_profile
     * @return {string}
     */
	public get compensation_grade_profile(): string {
		return this._compensation_grade_profile;
	}

    /**
     * Getter collective_group
     * @return {string}
     */
	public get collective_group(): string {
		return this._collective_group;
	}

    /**
     * Getter collective_agreement
     * @return {string}
     */
	public get collective_agreement(): string {
		return this._collective_agreement;
	}

    /**
     * Getter salary_group
     * @return {string}
     */
	public get salary_group(): string {
		return this._salary_group;
	}

    /**
     * Getter job_family_group
     * @return {string}
     */
	public get job_family_group(): string {
		return this._job_family_group;
	}

    /**
     * Getter job_family
     * @return {string}
     */
	public get job_family(): string {
		return this._job_family;
	}

    /**
     * Getter job_profile
     * @return {string}
     */
	public get job_profile(): string {
		return this._job_profile;
	}

    /**
     * Getter costcenter_id
     * @return {number}
     */
	public get costcenter_id(): number {
		return this._costcenter_id;
	}

    /**
     * Getter contract_type
     * @return {string}
     */
	public get contract_type(): string {
		return this._contract_type;
	}

    /**
     * Getter costcenter
     * @return {Costcenter}
     */
	public get costcenter(): Costcenter {
		return this._costcenter;
    }
    
    /**
     * Getter dirty
     * @return {boolean}
     */
	public get dirty(): boolean {
		return this._dirty;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
        this._dirty = true;
		this._id = value;
	}

    /**
     * Setter person_id
     * @param {number} value
     */
	public set person_id(value: number) {
        this._dirty = true;
		this._person_id = value;
	}

    /**
     * Setter start_date
     * @param {Date} value
     */
	public set start_date(value: Date) {
        if (typeof value === 'string') { value = new Date(value); }
        this._dirty = true;
        this._start_date = value;
	}

    /**
     * Setter end_date
     * @param {Date} value
     */
	public set end_date(value: Date) {
        if (typeof value === 'string') { value = new Date(value); }
        this._dirty = true;
		this._end_date = value;
	}

    /**
     * Setter position_effective_date
     * @param {Date} value
     */
	public set position_effective_date(value: Date) {
        if (typeof value === 'string') { value = new Date(value); }
        this._dirty = true;
		this._position_effective_date = value;
	}

    /**
     * Setter worker_type
     * @param {string} value
     */
	public set worker_type(value: string) {
        this._dirty = true;
		this._worker_type = value;
	}

    /**
     * Setter weekly_hours
     * @param {number} value
     */
	public set weekly_hours(value: number) {
        this._dirty = true;
		this._weekly_hours = value;
	}

    /**
     * Setter organisation
     * @param {string} value
     */
	public set organisation(value: string) {
        this._dirty = true;
		this._organisation = value;
	}

    /**
     * Setter organisation_type
     * @param {string} value
     */
	public set organisation_type(value: string) {
        this._dirty = true;
		this._organisation_type = value;
	}

    /**
     * Setter business_site
     * @param {string} value
     */
	public set business_site(value: string) {
        this._dirty = true;
		this._business_site = value;
	}

    /**
     * Setter supervisor_id
     * @param {string} value
     */
	public set supervisor_id(value: string) {
        this._dirty = true;
		this._supervisor_id = value;
	}

    /**
     * Setter vacation_in_days
     * @param {number} value
     */
	public set vacation_in_days(value: number) {
        this._dirty = true;
		this._vacation_in_days = value;
	}

    /**
     * Setter is_active
     * @param {number} value
     */
	public set is_active(value: number) {
        this._dirty = true;
		this._is_active = value;
	}

    /**
     * Setter compensation_grade_profile
     * @param {string} value
     */
	public set compensation_grade_profile(value: string) {
        this._dirty = true;
		this._compensation_grade_profile = value;
	}

    /**
     * Setter collective_group
     * @param {string} value
     */
	public set collective_group(value: string) {
        this._dirty = true;
		this._collective_group = value;
	}

    /**
     * Setter collective_agreement
     * @param {string} value
     */
	public set collective_agreement(value: string) {
        this._dirty = true;
		this._collective_agreement = value;
	}

    /**
     * Setter salary_group
     * @param {string} value
     */
	public set salary_group(value: string) {
        this._dirty = true;
		this._salary_group = value;
	}

    /**
     * Setter job_family_group
     * @param {string} value
     */
	public set job_family_group(value: string) {
        this._dirty = true;
		this._job_family_group = value;
	}

    /**
     * Setter job_family
     * @param {string} value
     */
	public set job_family(value: string) {
        this._dirty = true;
		this._job_family = value;
	}

    /**
     * Setter job_profile
     * @param {string} value
     */
	public set job_profile(value: string) {
        this._dirty = true;
		this._job_profile = value;
	}

    /**
     * Setter costcenter_id
     * @param {number} value
     */
	public set costcenter_id(value: number) {
        this._dirty = true;
		this._costcenter_id = value;
	}

    /**
     * Setter contract_type
     * @param {string} value
     */
	public set contract_type(value: string) {
        this._dirty = true;
		this._contract_type = value;
	}

    /**
     * Setter costcenter
     * @param {Costcenter} value
     */
	public set costcenter(value: Costcenter) {
        this._dirty = true;
        this._costcenter_id = value.id;
		this._costcenter = value;
    }
    
    /**
     * Setter dirty
     * @param {boolean} value
     */
	public set dirty(value: boolean) {
        this._dirty = value;
	}

}
