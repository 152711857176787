import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { ApiService } from './api.service';
import { IConstants } from '../interfaces/i-constants';
import { Costcenter } from '../classes/costcenter';
import { Country } from '../classes/country';
import { EmployerType } from '../classes/employer-type';
import { Employer } from '../classes/employer';
import { Gender } from '../classes/gender';
import { Language } from '../classes/language';
import { Source } from '../classes/source';
import { Application } from '../classes/application';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  public constants: IConstants  = {
    costcenters: [], countries: [], employer_types: [], employers: [],
    genders: [], languages: [], sources: [], applications: [],
    business_sites: [], collective_agreements: [], collective_groups: [],
    compensation_grade_profiles: [], contract_types: [], job_families: [],
    job_family_groups: [], job_profiles: [], organisation_types: [],
    organisations: [], salary_groups: [], worker_types: []
  };

  constructor(private http: HttpClient,
              private headerService: HeaderService,
              private api: ApiService) { }

  @Output() change: EventEmitter<any> = new EventEmitter();

  toggle(constants: Object) {
    this.constants = this.fromObject(constants);
    this.change.emit(this.constants);
  }       

  public getConstants() {
    return this.http.get<any>(this.api.GET_CONSTANTS, this.headerService.getOptions())
  }

  private fromObject(obj: any): IConstants {
    let costcenters: Array<Costcenter> = [];
    costcenters.push(new Costcenter())
    for (let elem of obj.costcenters) { costcenters.push(new Costcenter(elem)) }
    let countries: Array<Country> = [];
    for (let elem of obj.countries) { countries.push(new Country(elem)) }
    let employer_types: Array<EmployerType> = [];
    for (let elem of obj.employer_types) { employer_types.push(new EmployerType(elem)) }
    let employers: Array<Employer> = [];
    for (let elem of obj.employers) { employers.push(new Employer(elem)) }
    let genders: Array<Gender> = [];
    for (let elem of obj.genders) { genders.push(new Gender(elem)) }
    let languages: Array<Language> = [];
    for (let elem of obj.languages) { languages.push(new Language(elem)) }
    let sources: Array<Source> = [];
    for (let elem of obj.sources) { sources.push(new Source(elem)) }
    let applications: Array<Application> = [];
    for (let elem of obj.applications) { applications.push(new Application(elem)) }
    let constants: IConstants = {
      costcenters: costcenters,
      countries: countries,
      employer_types: employer_types,
      employers: employers,
      genders: genders,
      languages: languages,
      sources: sources,
      applications: applications,
      business_sites: obj.business_sites,
      collective_agreements: obj.collective_agreements,
      collective_groups: obj.collective_groups,
      compensation_grade_profiles: obj.compensation_grade_profiles,
      contract_types: obj.contract_types,
      job_families: obj.job_families,
      job_family_groups: obj.job_family_groups,
      job_profiles: obj.job_profiles,
      organisation_types: obj.organisation_types,
      organisations: obj.organisations,
      salary_groups: obj.salary_groups,
      worker_types: obj.worker_types
    }
    return constants;
  }
}
