export class Gender {

    private _id: number;
    private _gender: string;

    constructor(obj?: any) {
        try {
            this._id = obj.id;
            this._gender = obj.gender;
        } catch (err) {}
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter gender
     * @return {string}
     */
	public get gender(): string {
		return this._gender;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter gender
     * @param {string} value
     */
	public set gender(value: string) {
		this._gender = value;
	}

}
