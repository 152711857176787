<div class="container">
  <form (submit)="router.navigate(['/search/' + query])">
    <input type="search" id="search-input" name="query" placeholder="{{stringsService.strings['SEARCH']}}" [(ngModel)]="query" />
  </form>
  <div id="empty-result" *ngIf="!people || people.length === 0">
    <p>{{stringsService.strings['NO_RESULTS_FOUND']}}</p>
  </div>
  <table id="searchtable" class="maintable" *ngIf="people && people.length > 0">
      <tr>
          <th>{{stringsService.strings['NAME']}}</th>
          <th>{{stringsService.strings['EMPNUMBER']}}</th>
          <th>{{stringsService.strings['WORK_EMAIL']}}</th>
          <th>{{stringsService.strings['WORK_CELLPHONE']}}</th>
      </tr>
      
      <tbody *ngFor="let person of people" (click)="router.navigate(['/employee/' + person.id])">
        <tr>
          <td>{{person.first_name}} {{person.last_name}}</td>
          <td>{{person.employee_number}}</td>
          <td><span *ngIf="person.work_email">{{person.work_email}}</span></td>
          <td><span *ngIf="person.work_cellphone">{{person.work_cellphone}}</span></td>
        </tr>
      </tbody>
    </table>
</div>
