import { DateService } from "../services/date.service";

export class License {

    private _person_id: number;
    private _license_number: string;
    private _valid_from: Date;
    private _valid_to: Date;
    private _dirty: boolean;
    private dateService: DateService = new DateService();

    constructor(obj?: any) {
        try {
            this._person_id = obj.person_id;
            this._license_number = obj.license_number;
            this._valid_from = (obj.valid_from) ? new Date(obj.valid_from) : undefined;
            this._valid_to = (obj.valid_to) ? new Date(obj.valid_to) : undefined;
        } catch (err) {}
    }

    public toJSON() {
        let valid_from = (!this._valid_from) ? null : this.dateService.format(this._valid_from, 'yyyy-mm-dd');
        let valid_to = (!this._valid_to) ? null : this.dateService.format(this._valid_to, 'yyyy-mm-dd');
        return {
            person_id: this._person_id,
            license_number: this._license_number,
            valid_from: valid_from,
            valid_to: valid_to
        }
    }

    /**
     * Getter person_id
     * @return {number}
     */
	public get person_id(): number {
		return this._person_id;
	}

    /**
     * Getter license_number
     * @return {string}
     */
	public get license_number(): string {
		return this._license_number;
	}

    /**
     * Getter valid_from
     * @return {Date}
     */
	public get valid_from(): Date {
		return this._valid_from;
	}

    /**
     * Getter valid_to
     * @return {Date}
     */
	public get valid_to(): Date {
		return this._valid_to;
    }
    
    /**
     * Getter dirty
     * @return {boolean}
     */
	public get dirty(): boolean {
		return this._dirty;
	}

    /**
     * Setter person_id
     * @param {number} value
     */
	public set person_id(value: number) {
        this._dirty = true;
		this._person_id = value;
	}

    /**
     * Setter license_number
     * @param {string} value
     */
	public set license_number(value: string) {
        this._dirty = true;
		this._license_number = value;
	}

    /**
     * Setter valid_from
     * @param {Date} value
     */
	public set valid_from(value: Date) {
        if (typeof value === 'string') { value = new Date(value); }
        this._dirty = true;
		this._valid_from = value;
	}

    /**
     * Setter valid_to
     * @param {Date} value
     */
	public set valid_to(value: Date) {
        if (typeof value === 'string') { value = new Date(value); }
        this._dirty = true;
		this._valid_to = value;
    }
    
    /**
     * Setter dirty
     * @param {boolean} value
     */
	public set dirty(value: boolean) {
        this._dirty = value;
	}

}
