import { Component, OnInit, Input } from '@angular/core';
import { StringService } from '../../../services/string.service';
import { IConstants } from '../../../interfaces/i-constants';
import { ConstantsService } from '../../../services/constants.service';
import { CompareService } from '../../../services/compare.service';
import { Employee } from '../../../classes/employee';
import { EmploymentConditions } from '../../../classes/employment-conditions';
import { DateService } from '../../../services/date.service';

@Component({
  selector: 'app-contract-data',
  templateUrl: './contract-data.component.html',
  styleUrls: ['./contract-data.component.scss']
})
export class ContractDataComponent implements OnInit {

  @Input() employee: Employee;
  @Input() fieldsEnabled: boolean;
  @Input() contract: EmploymentConditions;
  public constants: IConstants;

  constructor(public stringsService: StringService,
              private constantsService: ConstantsService,
              public compareService: CompareService,
              public dateService: DateService) {
    this.constants = constantsService.constants;
  }

  ngOnInit() {
    console.log(this.contract);
    this.constantsService.change.subscribe((constants: IConstants) => { this.constants = constants; })
  }

  addContract() {
    if (!this.contract.end_date) {
      alert(this.stringsService.strings['MISSING_CONTRACT_END']);
    } else if (!this.contract.id) {
      alert("Current contract is not saved. A new one cannot be added (TEST)")
    } else {
      let newContract = new EmploymentConditions();
      newContract.person_id = this.contract.person_id;
      newContract.start_date = new Date();
      newContract.start_date.setDate(this.contract.end_date.getDate() + 1);
      newContract.contract_type = this.contract.contract_type;
      newContract.worker_type = this.contract.worker_type;
      newContract.weekly_hours = this.contract.weekly_hours;
      newContract.supervisor_id = this.contract.supervisor_id;
      newContract.organisation = this.contract.organisation;
      newContract.organisation_type = this.contract.organisation_type;
      newContract.business_site = this.contract.business_site;
      newContract.costcenter_id = this.contract.costcenter_id;
      newContract.costcenter = this.contract.costcenter;
      newContract.compensation_grade_profile = this.contract.compensation_grade_profile;
      newContract.collective_group = this.contract.collective_group;
      newContract.collective_agreement = this.contract.collective_agreement;
      newContract.salary_group = this.contract.salary_group;
      newContract.job_family_group = this.contract.job_family_group;
      newContract.job_family = this.contract.job_family;
      newContract.job_profile = this.contract.job_profile;
      this.employee.employment_conditions.push(newContract);
      this.contract = newContract;
      this.employee.dirty = true;
    }
  }

}
