import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateSorter'
})
export class DateSorterPipe implements PipeTransform {

  transform(items: any[], field: string, order?: string): any {
    if (!items || !field) {
      return items;
    }
    return items.sort((a, b) => {
      if (order === 'reverse') return b[field] - a[field];
      else return a[field] - b[field];
    })
  }
}
