
<div class="password-container">
    <img src="{{application['icon_url']}}" id="logo">
    <p id="title">{{stringService.connectionStrings['REQUEST_PASSWORD']}}</p>
    <div class="animation-container" *ngIf="!requestSent">
        <div class="form-container">
            <form [formGroup]="requestNewPasswordForm" (ngSubmit)="request()" class="form">
                <input type="email" formControlName="email" name="email" class="form-input" placeholder="{{stringService.connectionStrings['EMAIL']}}" autofocus>
                <input type="submit" class="form-submit" value="{{stringService.connectionStrings['SEND']}}">
            </form>
        </div>
        <p class="stamp" style="bottom:20px;">{{stringService.connectionStrings['REQUEST_PASSWORD_EXPLANATION']}}</p>
        <p class="stamp"><span><img src="assets/img/union-logo.png" style="height:10px;">&nbsp;&nbsp;</span>Powered by union</p>
    </div>

    <div class="animation-container" *ngIf="requestSent">
        <div id="form-container" style="font-size: 14px">
                <p>{{address}}</p>
                <p>{{stringService.connectionStrings['EMAIL_WAS_SENT']}}</p>
            <input type="submit" class="form-submit" (click)="request()" value="{{stringService.connectionStrings['RESEND_EMAIL']}}">
        </div>
        
    </div>
</div>

