<div class="container">
    <h3>{{stringsService.strings['MASTER_DATA']}}</h3>
    <div id="image-holder">
        <img *ngIf="employee.image" style="height:130px" [src]="sanitizer.bypassSecurityTrustResourceUrl('data:application/jpg;base64,' + employee.image)" id="user-image"/>
    </div>
    <div style="clear:both"></div>
    <button (click)="imageCropperService.showCropper(true);" *ngIf="mode==='UPDATE'" id="change-image">{{stringsService.strings['CHANGE_IMAGE']}}</button>
    <form style="text-align: center;padding-bottom: 40px;">
        <div *ngIf="mode==='UPDATE'">
            <mat-form-field class="center-align" >
                <mat-label>{{stringsService.strings['EMPNUMBER']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.employee_number" name="employee.employee_number" disabled>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>{{stringsService.strings['FIRST_NAME']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.first_name" name="employee.first_name" required>
            </mat-form-field>
            &nbsp;
            <mat-form-field>
                <mat-label>{{stringsService.strings['LAST_NAME']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.last_name" name="employee.last_name" required>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>{{stringsService.strings['HIRE_DATE']}}</mat-label>
                <input matInput type="date" [disabled]="!fieldsEnabled" [ngModel]="employee.hire_date | date:'yyyy-MM-dd'" name="employee.hire_date" (ngModelChange)="employee.hire_date = dateService.validate($event)">
            </mat-form-field>
            &nbsp;
            <mat-form-field>
                <mat-label>{{stringsService.strings['BIRTH_DATE']}}</mat-label>
                <input matInput type="date" [disabled]="!fieldsEnabled" [ngModel]="employee.birth_date | date:'yyyy-MM-dd'" name="employee.birth_date" (ngModelChange)="employee.birth_date = dateService.validate($event)">
            </mat-form-field>
            &nbsp;
            <mat-form-field>
                <mat-label>{{stringsService.strings['BIRTH_PLACE']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.birth_place" name="employee.birth_place">
            </mat-form-field>
            &nbsp;
            <mat-form-field>
                <mat-label>{{stringsService.strings['NATIONALITY']}}</mat-label>
                <mat-select matInput name="employee.nationality" [(ngModel)]="employee.nationality" [compareWith]="compareService.ids" [disabled]="!fieldsEnabled">
                    <mat-option *ngFor="let country of constants.countries" [value]="country">
                        {{stringsService.strings[country.country]}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field >
                <mat-label>{{stringsService.strings['PRIVATE_ADDRESS']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.private_address" name="employee.private_address">
            </mat-form-field>
            &nbsp;
            <mat-form-field >
                <mat-label>{{stringsService.strings['PRIVATE_EMAIL']}}</mat-label>
                <input matInput type="email" [disabled]="!fieldsEnabled" [(ngModel)]="employee.private_email" name="employee.private_email">
            </mat-form-field>
            &nbsp;
            <mat-form-field >
                <mat-label>{{stringsService.strings['PRIVATE_TELEPHONE']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.private_telephone" name="employee.private_telephone">
            </mat-form-field>
            &nbsp;
            <mat-form-field >
                <mat-label>{{stringsService.strings['PRIVATE_CELLPHONE']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.private_cellphone" name="employee.private_cellphone">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field >
                <mat-label>{{stringsService.strings['WORK_ADDRESS']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.work_address" name="employee.work_address">
            </mat-form-field>
            &nbsp;
            <mat-form-field >
                <mat-label>{{stringsService.strings['WORK_EMAIL']}}</mat-label>
                <input matInput type="email" [disabled]="!fieldsEnabled" [(ngModel)]="employee.work_email" name="employee.work_email">
            </mat-form-field>
            &nbsp;
            <mat-form-field >
                <mat-label>{{stringsService.strings['WORK_TELEPHONE']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.work_telephone" name="employee.telephone">
            </mat-form-field>
            &nbsp;
            <mat-form-field >
                <mat-label>{{stringsService.strings['WORK_CELLPHONE']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.work_cellphone" name="employee.cellphone">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>{{stringsService.strings['EMPLOYER']}}</mat-label>
                <mat-select matInput name="employee.employer" [(ngModel)]="employee.employer" [compareWith]="compareService.ids" [disabled]="!fieldsEnabled" required>
                    <mat-option *ngFor="let employer of constants.employers" [value]="employer">
                        {{employer.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;
            <mat-form-field >
                <mat-label>{{stringsService.strings['GENDER']}}</mat-label>
                <mat-select matInput name="employee.gender" [(ngModel)]="employee.gender" [compareWith]="compareService.ids" [disabled]="!fieldsEnabled">
                    <mat-option *ngFor="let gender of constants.genders" [value]="gender">
                        {{stringsService.strings[gender.gender]}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;
            <mat-form-field >
                <mat-label>{{stringsService.strings['NEXT_OF_KIN']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.spouse_name" name="employee.spouse_name">
            </mat-form-field>
            &nbsp;
            <mat-form-field >
                <mat-label>{{stringsService.strings['NEXT_OF_KIN_CELLPHONE']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.spouse_cellphone" name="employee.spouse_cellphone">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>{{stringsService.strings['LICENSE_NUMBER']}}</mat-label>
                <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="employee.license.license_number" name="employee.license_number">
            </mat-form-field>
            &nbsp;
            <mat-form-field>
                <mat-label>{{stringsService.strings['LICENSE_VALID_FROM']}}</mat-label>
                <input matInput type="date" [disabled]="!fieldsEnabled" [ngModel]="employee.license.valid_from | date:'yyyy-MM-dd'" name="employee.license_valid_from" (ngModelChange)="employee.license.valid_from = dateService.validate($event)">
            </mat-form-field>
            &nbsp;
            <mat-form-field>
                <mat-label>{{stringsService.strings['LICENSE_VALID_TO']}}</mat-label>
                <input matInput type="date" [disabled]="!fieldsEnabled" [ngModel]="employee.license.valid_to | date:'yyyy-MM-dd'" name="employee.license_valid_to" (ngModelChange)="employee.license.valid_to = dateService.validate($event)">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>{{stringsService.strings['AUTOMATIC_DELETION']}}</mat-label>
                <input matInput type="date" [ngModel]="employee.planned_deletion | date:'yyyy-MM-dd'" name="employee.planned_deletion" (ngModelChange)="employee.planned_deletion = dateService.validate($event)">
            </mat-form-field>
        </div>
    </form>
</div>
