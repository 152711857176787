import { Country } from "./country";
import { EmployerType } from "./employer-type";

export class Employer {

    private _id: number;
    private _name: string;
    private _address: string;
    private _zipcode: string;
    private _city: string;
    private _country_id: number;
    private _employer_type_id: number;
    private _phone_number: string;
    private _email: string;
    private _country: Country;
    private _employer_type: EmployerType;

    constructor(obj?: any) {
        try {
            this._id = obj.id;
            this._name = obj.name;
            this._address = obj.address;
            this._zipcode = obj.zipcode;
            this._city = obj.city;
            this._country_id = obj.country_id;
            this._employer_type_id = obj.employer_type_id;
            this._phone_number = obj.phone_number;
            this._email = obj.email;
            this._country = new Country(obj.country);
            this._employer_type = new EmployerType(obj.employer_type);
        } catch (err) {}
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter name
     * @return {string}
     */
	public get name(): string {
		return this._name;
	}

    /**
     * Getter address
     * @return {string}
     */
	public get address(): string {
		return this._address;
	}

    /**
     * Getter zipcode
     * @return {string}
     */
	public get zipcode(): string {
		return this._zipcode;
	}

    /**
     * Getter city
     * @return {string}
     */
	public get city(): string {
		return this._city;
	}

    /**
     * Getter country_id
     * @return {number}
     */
	public get country_id(): number {
		return this._country_id;
	}

    /**
     * Getter employer_type_id
     * @return {number}
     */
	public get employer_type_id(): number {
		return this._employer_type_id;
	}

    /**
     * Getter phone_number
     * @return {string}
     */
	public get phone_number(): string {
		return this._phone_number;
	}

    /**
     * Getter email
     * @return {string}
     */
	public get email(): string {
		return this._email;
	}

    /**
     * Getter country
     * @return {Country}
     */
	public get country(): Country {
		return this._country;
	}

    /**
     * Getter employer_type
     * @return {EmployerType}
     */
	public get employer_type(): EmployerType {
		return this._employer_type;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter name
     * @param {string} value
     */
	public set name(value: string) {
		this._name = value;
	}

    /**
     * Setter address
     * @param {string} value
     */
	public set address(value: string) {
		this._address = value;
	}

    /**
     * Setter zipcode
     * @param {string} value
     */
	public set zipcode(value: string) {
		this._zipcode = value;
	}

    /**
     * Setter city
     * @param {string} value
     */
	public set city(value: string) {
		this._city = value;
	}

    /**
     * Setter country_id
     * @param {number} value
     */
	public set country_id(value: number) {
		this._country_id = value;
	}

    /**
     * Setter employer_type_id
     * @param {number} value
     */
	public set employer_type_id(value: number) {
		this._employer_type_id = value;
	}

    /**
     * Setter phone_number
     * @param {string} value
     */
	public set phone_number(value: string) {
		this._phone_number = value;
	}

    /**
     * Setter email
     * @param {string} value
     */
	public set email(value: string) {
		this._email = value;
	}

    /**
     * Setter country
     * @param {Country} value
     */
	public set country(value: Country) {
		this._country = value;
	}

    /**
     * Setter employer_type
     * @param {EmployerType} value
     */
	public set employer_type(value: EmployerType) {
		this._employer_type = value;
	}

}
