import { Component, OnInit, ViewChild, Input } from '@angular/core';
//import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-image-cropper';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageCropperService } from '../../services/image-cropper.service';

@Component({
  selector: 'app-profile-image-cropper',
  templateUrl: './profile-image-cropper.component.html',
  styleUrls: ['./profile-image-cropper.component.scss']
})
export class ProfileImageCropperComponent implements OnInit {

  @Input() image: any;
  data: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageSelected: boolean = false;

  constructor(public imageCropperService: ImageCropperService) {}

  ngOnInit() {
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
    this.imageSelected = true;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {

  }
  cropperReady() {

  }
  loadImageFailed() {

  }

  upload() {
    let image = this.croppedImage.split('data:image/jpeg;base64,')[1];
    this.imageCropperService.setImage(image);
    this.imageCropperService.showCropper(false);
  }
}
