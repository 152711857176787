import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { HeaderService } from './header.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class StringService {
  public strings: any = {};
  public connectionStrings: any = {};

  constructor(private cookie: CookieService,
              private http: HttpClient,
              private api: ApiService,
              private headers: HeaderService) { }

  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() connectionStringsChange: EventEmitter<any> = new EventEmitter();

  toggle(strings: any) {
    this.strings = strings;
    this.change.emit(strings);
  }

  toggleConnectionStrings(strings: any) {
    this.connectionStrings = strings;
    this.connectionStringsChange.emit(strings);
  }

  public getStrings(language?: string, connect?: boolean) {
    if (language === undefined || language === "") {
      language = this.cookie.get("language");
      if (language === undefined || language === "") {
        language = navigator.language;
        language = language.split('-')[0];
      }
    }
    let location = (!connect) ? this.api.GET_STRINGS : this.api.GET_CONNECTION_STRINGS;
    this.cookie.set("language", language, 60 * 60 * 24 * 30, "/", "/", true, "None");
    return this.http.get<any>(location + "/" + language, this.headers.getOptions())
      .pipe(
        map(
          (response) => { return response; },
        )
      )
  }
}
