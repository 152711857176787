export class Costcenter {

    private _id: number;
    private _costcenter: string;
    private _name: string;
    private _email: string;

    constructor(obj?: any) {
        try {
            this._id = obj.id;
            this._costcenter = obj.costcenter;
            this._name = obj.name;
            this._email = obj.email;
        } catch (err) {}
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter costcenter
     * @return {string}
     */
	public get costcenter(): string {
		return this._costcenter;
	}

    /**
     * Getter name
     * @return {string}
     */
	public get name(): string {
		return this._name;
	}

    /**
     * Getter email
     * @return {string}
     */
	public get email(): string {
		return this._email;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter costcenter
     * @param {string} value
     */
	public set costcenter(value: string) {
		this._costcenter = value;
	}

    /**
     * Setter name
     * @param {string} value
     */
	public set name(value: string) {
		this._name = value;
	}

    /**
     * Setter email
     * @param {string} value
     */
	public set email(value: string) {
		this._email = value;
	}

    
}
