import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmRequestComponent } from '../components/confirm-request/confirm-request.component';
import { NewPasswordComponent } from '../components/new-password/new-password.component';
import { RequestNewPasswordComponent } from '../components/request-new-password/request-new-password.component';
import { StartComponent } from '../components/start/start.component';
import { EmployeeListComponent } from '../components/employee-list/employee-list.component';
import { EmployeeComponent } from '../components/employee/employee.component';

const routes: Routes = [
  { path: '', redirectTo: 'start', pathMatch: 'full' },
  { path: 'confirm-request/:application/:token', component: ConfirmRequestComponent },
  { path: 'create-password/:username/:application/:token', component: NewPasswordComponent }, 
  { path: 'start', component: StartComponent },
  { path: 'request-password/:application', component: RequestNewPasswordComponent },
  { path: 'search/:query', component: EmployeeListComponent },
  { path: 'employee/:id', component: EmployeeComponent },
  { path: 'employee', component: EmployeeComponent },
  { path: 'start/:code', component: StartComponent },
  { path: 'start/:code/:state', component: StartComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
