import { Component, OnInit, Input } from '@angular/core';
import { IConstants } from '../../../interfaces/i-constants';
import { ConstantsService } from '../../../services/constants.service';
import { StringService } from '../../../services/string.service';
import { CompareService } from '../../../services/compare.service';
import { BrokerService } from '../../../services/broker.service';
import { Employee } from '../../../classes/employee';
import { Application } from '../../../classes/application';
import { Role } from '../../../classes/role';
import { SaveStateService } from '../../../services/save-state.service';

@Component({
  selector: 'app-application-access',
  templateUrl: './application-access.component.html',
  styleUrls: ['./application-access.component.scss']
})
export class ApplicationAccessComponent implements OnInit {

  @Input() employee: Employee;
  @Input() roleChangesEnabled: boolean;
  public constants: IConstants;

  constructor(private constantsService: ConstantsService,
              public stringsService: StringService,
              public compareService: CompareService,
              private broker: BrokerService,
              private saveState: SaveStateService) {
    this.constants = constantsService.constants
  }

  ngOnInit() {
    this.constantsService.change.subscribe((constants: IConstants) => { this.constants = constants; })
  }

  public changeRole(role: Role) {
    let application: Application = this.constants.applications.find((app: Application) => { return app.id === role.application_id });
    let question = this.stringsService.strings['UPDATING_ROLE'] + "\n" + 
      this.stringsService.strings['APPLICATION'] + ": " + application.application + "\n" + 
      this.stringsService.strings['NEW_ROLE'] + ": " + this.stringsService.strings[role.role] + "\n" + 
      this.stringsService.strings['ARE_YOU_SURE'];
    if (confirm(question)) {
      this.saveState.state = false;
      this.broker.updateRole(this.employee.id, role.application_id, role.id).subscribe(
        () => { this.saveState.state = true },
        () => {
          this.employee.resetRole(application.application);
        }
      )
    } else {
      this.employee.resetRole(application.application);
    }
  }

  public defaultRole(applicationName: string): Role {
    if (!applicationName) return;
    let app: Application = this.constants.applications.find((application: Application) => { return application.application === applicationName })
    if (!app) return;
    return new Role({ id: 0, role: this.stringsService.strings['NO_ACCESS'], application_id: app.id })
  }
}
