import { Component, OnInit, Input } from '@angular/core';
import { LoginStateService } from '../../services/login-state.service';
import { Router } from '@angular/router';
import { StringService } from '../../services/string.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() appTitle: string;
  loggedIn: boolean = false;

  constructor(public loginStateService: LoginStateService,
              public router: Router,
              public stringService: StringService,
              private authenticator: AuthenticationService) { }

  ngOnInit() {}

  logout() {
    this.authenticator.logout();
  }

}
