import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { ConfirmRequestComponent } from './components/confirm-request/confirm-request.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { StartComponent } from './components/start/start.component';
import { EmployeeListComponent } from './components/employee-list/employee-list.component';
import { RequestNewPasswordComponent } from './components/request-new-password/request-new-password.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { MasterDataComponent } from './components/employee/master-data/master-data.component';
import { ContractDataComponent } from './components/employee/contract-data/contract-data.component';
import { ApplicationAccessComponent } from './components/employee/application-access/application-access.component';
import { ProfileImageCropperComponent } from './components/profile-image-cropper/profile-image-cropper.component';
import { InterfacesComponent } from './components/employee/interfaces/interfaces.component';
import { ApplicationFilterPipe } from './pipes/application-filter.pipe';
import { HistoryComponent } from './components/employee/history/history.component';
import { DateSorterPipe } from './pipes/date-sorter.pipe';
import { SettingsComponent } from './components/settings/settings.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ConfirmRequestComponent,
    NewPasswordComponent,
    StartComponent,
    EmployeeListComponent,
    RequestNewPasswordComponent,
    EmployeeComponent,
    MasterDataComponent,
    ContractDataComponent,
    ApplicationAccessComponent,
    ProfileImageCropperComponent,
    InterfacesComponent,
    ApplicationFilterPipe,
    HistoryComponent,
    DateSorterPipe,
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ImageCropperModule
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}
