import { Language } from "./language";

export class Country {

    private _id: number;
    private _country: string;
    private _language_id: number;
    private _language: Language;

    constructor(obj?: any) {
        try {
            this._id = obj.id;
            this._country = obj.country;
            this._language_id = obj.language_id;
            this._language = new Language(obj.language);
        } catch (err) {}
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter country
     * @return {string}
     */
	public get country(): string {
		return this._country;
	}

    /**
     * Getter language_id
     * @return {number}
     */
	public get language_id(): number {
		return this._language_id;
	}

    /**
     * Getter language
     * @return {Language}
     */
	public get language(): Language {
		return this._language;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter country
     * @param {string} value
     */
	public set country(value: string) {
		this._country = value;
	}

    /**
     * Setter language_id
     * @param {number} value
     */
	public set language_id(value: number) {
		this._language_id = value;
	}

    /**
     * Setter language
     * @param {Language} value
     */
	public set language(value: Language) {
		this._language = value;
	}
    
}
