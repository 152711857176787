import { Injectable, Output, EventEmitter, Directive } from '@angular/core';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ImageCropperService {

  public showImageCropper: boolean = false;

  constructor() { }

  @Output() show: EventEmitter<any> = new EventEmitter();
  @Output() image: EventEmitter<any> = new EventEmitter();

  public showCropper(show: boolean) {
    this.showImageCropper = show;
    this.show.emit(show);
  }

  public setImage(data: any) {
    this.image.emit(data);
  }
}
