import { Injectable, Directive } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class LoginStateService {

  public loggedIn: boolean = false;

  constructor(private router: Router) { }

  @Output() loggedInChange: EventEmitter<any> = new EventEmitter();

  public toggleLoggedIn(loggedIn: boolean) {
    this.loggedIn = loggedIn;
    this.loggedInChange.emit(loggedIn);
  }

  public logout() {
    this.toggleLoggedIn(false);
    this.router.navigate(['/start']);
  }
}
