<div class="container">
    <div *ngIf="employee.employment_conditions.length === 0">
        <h3>{{stringsService.strings['CURRENT_CONTRACT']}}</h3>
        <p>{{stringsService.strings['NO_CURRENT_CONTRACT']}}</p>
    </div>
    <mat-tab-group *ngIf="employee.employment_conditions.length > 0">
        <mat-tab *ngFor="let contract of employee.employment_conditions | dateSorter: 'start_date':'reverse'" label="{{dateService.format(contract.start_date, 'yyyy-mm-dd')}}" style="overflow-y: hidden;">
            <form style="text-align: center; margin: 50px; padding-bottom: 40px;">
                <div>
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['START_DATE']}}</mat-label>
                        <input matInput 
                            type="date" 
                            [disabled]="!fieldsEnabled" 
                            [ngModel]="contract.start_date | date:'yyyy-MM-dd'" 
                            name="employee.contract.start_date" 
                            (ngModelChange)="contract.start_date = dateService.validate($event)" 
                            required>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['END_DATE']}}</mat-label>
                        <input matInput 
                            type="date" 
                            [disabled]="!fieldsEnabled" 
                            [ngModel]="contract.end_date | date:'yyyy-MM-dd'" 
                            name="employee.contract.end_date" 
                            (ngModelChange)="contract.end_date = dateService.validate($event)">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['CONTRACT_TYPE']}}</mat-label>
                        <mat-select matInput name="employee.contract.contract_type" [(ngModel)]="contract.contract_type" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let contract_type of constants.contract_types" [value]="contract_type">
                                {{contract_type}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['WORKER_TYPE']}}</mat-label>
                        <mat-select matInput name="employee.contract.worker_type" [(ngModel)]="contract.worker_type" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let worker_type of constants.worker_types" [value]="worker_type">
                                {{worker_type}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['WEEKLY_HOURS']}}</mat-label>
                        <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="contract.weekly_hours" name="employee.contract.weekly_hours">
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['SUPERVISOR_ID']}}</mat-label>
                        <input matInput type="text" [disabled]="!fieldsEnabled" [(ngModel)]="contract.supervisor_id" name="employee.contract.supervisor_id">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['ORGANISATION']}}</mat-label>
                        <mat-select matInput name="employee.contract.organisation" [(ngModel)]="contract.organisation" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let organisation of constants.organisations" [value]="organisation">
                                {{organisation}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['ORGANISATION_TYPE']}}</mat-label>
                        <mat-select matInput name="employee.contract.organisation_type" [(ngModel)]="contract.organisation_type" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let organisation_type of constants.organisation_types" [value]="organisation_type">
                                {{organisation_type}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['BUSINESS_SITE']}}</mat-label>
                        <mat-select matInput name="employee.contract.business_site" [(ngModel)]="contract.business_site" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let business_site of constants.business_sites" [value]="business_site">
                                {{business_site}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['COSTCENTER']}}</mat-label>
                        <mat-select matInput name="employee.contract.costcenter" [(ngModel)]="contract.costcenter" [compareWith]="compareService.ids" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let costcenter of constants.costcenters" [value]="costcenter">
                                {{costcenter.costcenter}} - {{costcenter.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['COMPENSATION_GRADE_PROFILE']}}</mat-label>
                        <mat-select matInput name="employee.contract.compensation_grade_profile" [(ngModel)]="contract.compensation_grade_profile" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let compensation_grade_profile of constants.compensation_grade_profiles" [value]="compensation_grade_profile">
                                {{compensation_grade_profile}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['COLLECTIVE_GROUP']}}</mat-label>
                        <mat-select matInput name="employee.contract.collective_group" [(ngModel)]="contract.collective_group" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let collective_group of constants.collective_groups" [value]="collective_group">
                                {{collective_group}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['COLLECTIVE_AGREEMENT']}}</mat-label>
                        <mat-select matInput name="employee.contract.collective_agreement" [(ngModel)]="contract.collective_agreement" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let collective_agreement of constants.collective_agreements" [value]="collective_agreement">
                                {{collective_agreement}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['SALARY_GROUP']}}</mat-label>
                        <mat-select matInput name="employee.contract.salary_group" [(ngModel)]="contract.salary_group" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let salary_group of constants.salary_groups" [value]="salary_group">
                                {{salary_group}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['JOB_FAMILY_GROUP']}}</mat-label>
                        <mat-select matInput name="employee.contract.job_family_group" [(ngModel)]="contract.job_family_group" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let job_family_group of constants.job_family_groups" [value]="job_family_group">
                                {{job_family_group}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['JOB_FAMILY']}}</mat-label>
                        <mat-select matInput name="employee.contract.job_family" [(ngModel)]="contract.job_family" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let job_family of constants.job_families" [value]="job_family">
                                {{job_family}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field>
                        <mat-label>{{stringsService.strings['JOB_PROFILE']}}</mat-label>
                        <mat-select matInput name="employee.contract.job_profile" [(ngModel)]="contract.job_profile" [disabled]="!fieldsEnabled">
                            <mat-option *ngFor="let job_profile of constants.job_profiles" [value]="job_profile">
                                {{job_profile}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </mat-tab>
    </mat-tab-group>
</div>
