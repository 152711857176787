export class Source {

    private _id: number;
    private _source: string;

    constructor(obj?: any) {
        try {
            this._id = obj.id;
            this._source = obj.source;
        } catch (err) {}
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter source
     * @return {string}
     */
	public get source(): string {
		return this._source;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter source
     * @param {string} value
     */
	public set source(value: string) {
		this._source = value;
	}

}
