import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StringService } from '../../services/string.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  requestForm: FormGroup;
  loading = false;
  loginSubmitted = false;
  requestSubmitted = false;
  returnUrl: string;
  state: number = 1;
  message: string = "";

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private cookie: CookieService,
              public stringsService: StringService,
              private auth: AuthenticationService) {
              }
  
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get form() { return this.loginForm.controls; }
  get request() { return this.requestForm.controls; }

  forgotPassword() {
    this.router.navigate(['/request-password/broker']);
  }

  login() {
    this.auth.requestAuthCode(location.origin + this.router.url);
  }

  public setLanguage(language: string): void {
    this.cookie.set('language', language, 60 * 60 * 24 * 30, '/', '/', true, 'None')
    this.stringsService.getStrings(language).subscribe( data => { this.stringsService.toggle(data);  });
    this.stringsService.getStrings(language, true).subscribe( data => {this.stringsService.toggleConnectionStrings(data) });
  }

}
