<div id="loginbox" class="modallogin" style="text-align:center;">
    <div class="modallogin-content animate">
        
        <div class="langcontainer">
            <img class="flag" id="german" (click)="setLanguage('deu')" src="assets/img/Germany-icon.png">
            <img class="flag" id="danish" (click)="setLanguage('dan')" src="assets/img/Denmark-icon.png">
            <img class="flag" id="english" (click)="setLanguage('eng')" src="assets/img/United-Kingdom-icon.png" >
            <img class="flag" id="french" (click)="setLanguage('fra')" src="assets/img/France-icon.png" >
        </div>
        
        <div class="imgcontainer" style="margin-top:0px">
            <img src="assets/img/union-logo.png" alt="Avatar" class="Avatar" style="height:150px;margin-top:15px;margin-bottom:15px;">
        </div>

        <button id="modal-login" (click)="login()">{{stringsService.connectionStrings['LOGIN']}}</button>
  
        <div style="text-align:right;margin-right:2px;">
            <span class="fake-link" (click)="forgotPassword()">{{stringsService.connectionStrings['FORGOT_PASSWORD']}}</span>
        </div>
    </div>
    
  </div>