import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { StringService } from '../../../services/string.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConstantsService } from '../../../services/constants.service';
import { IConstants } from '../../../interfaces/i-constants';
import { Employee } from '../../../classes/employee';
import { DomSanitizer } from "@angular/platform-browser";
import { ImageCropperService } from '../../../services/image-cropper.service';
import { CompareService } from '../../../services/compare.service';
import { BrokerService } from '../../../services/broker.service';
import { SaveStateService } from '../../../services/save-state.service';
import { Subscription } from 'rxjs';
import { DateService } from '../../../services/date.service';

@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.scss']
})
export class MasterDataComponent implements OnInit {

  @Input() employee: Employee;
  @Input() fieldsEnabled: boolean;
  @Input() mode: string;
  public form: FormGroup;
  public constants: IConstants;
  private imageSub: Subscription;

  constructor(public stringsService: StringService,
              private fb: FormBuilder,
              private constantsService: ConstantsService,
              public sanitizer: DomSanitizer,
              public imageCropperService: ImageCropperService,
              public compareService: CompareService,
              private broker: BrokerService,
              private saveState: SaveStateService,
              public dateService: DateService) {
    this.constants = constantsService.constants;
  }

  ngOnInit() {
    this.constantsService.change.subscribe((constants: IConstants) => { this.constants = constants; })
    this.imageSub = this.imageCropperService.image.subscribe((image: string) => { 
      if (image) {
        if (confirm(this.stringsService.strings['CHANGE_IMAGE'])) {
          this.saveState.state = false;
          if (this.employee.id) {
            this.broker.uploadImage(this.employee.id, image).subscribe(() => { 
                this.employee.image = image;
                this.saveState.state = true;
            })
          }
        }
      }
    })
  }

  ngOnDestroy() {
    this.imageSub.unsubscribe();
  }
}
