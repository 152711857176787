<div class="container">
    <div id="user-tabs">
        <h1 *ngIf="mode==='UPDATE'">{{employee.full_name}}</h1>
        <h1 *ngIf="mode==='CREATE'">{{stringsService.strings['CREATE_EMPLOYEE']}}</h1>
        <div id="changes" *ngIf="saveState.state || employee.dirty || (employee.newest_contract && employee.newest_contract.dirty) || (employee.license && employee.license.dirty)">
            <div *ngIf="!saveState.state || employee.dirty || (employee.newest_contract && employee.newest_contract.dirty) || (employee.license && employee.license.dirty)">
                <span style="color:orange">{{stringsService.strings['UNSAVED_CHANGES']}}</span>
                <div style="clear:both"></div>
                <button id="save" (click)="updateEmployee()">{{stringsService.strings['SAVE']}}</button>
                <button id="reset" (click)="resetEmployee()">{{stringsService.strings['RESET']}}</button>
            </div>
            <span style="color:green">{{dateService.now()}}</span>
            <br>
            <span *ngIf="saveState.state && !employee.dirty && (employee.newest_contract && !employee.newest_contract.dirty) && (employee.license && !employee.license.dirty)" style="color:green">{{stringsService.strings['SAVED_CHANGES']}}</span>
        </div>
        <mat-tab-group>
            <mat-tab label="{{stringsService.strings['MASTER_DATA']}}" style="overflow-y: hidden;">
                <app-master-data [employee]="employee" [fieldsEnabled]="authentication.role.role === ROLE_ADMIN && employee.source_id === 2" [mode]="mode"></app-master-data>
            </mat-tab>
            <mat-tab label="{{stringsService.strings['CONTRACT_DATA']}}">
                <app-contract-data [employee]="employee" [fieldsEnabled]="authentication.role.role === ROLE_ADMIN && employee.source_id === 2" [contract]="employee.newest_contract"></app-contract-data>
            </mat-tab>
            <mat-tab *ngIf="mode==='UPDATE'" label="{{stringsService.strings['APPLICATION_ACCESS']}}">
                <app-application-access [employee]="employee" [roleChangesEnabled]="authentication.role.role === ROLE_ADMIN"></app-application-access>
            </mat-tab>
            <mat-tab label="{{stringsService.strings['INTERFACES']}}">
                <app-interfaces [employee]="employee" [interfaceChangesEnabled]="authentication.role.role === ROLE_ADMIN || authentication.role.role === ROLE_HR"></app-interfaces>
            </mat-tab>
            <mat-tab *ngIf="mode==='UPDATE'" label="{{stringsService.strings['HISTORY']}}">
                <app-history [employee]="employee" [fieldsEnabled]="false"></app-history>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
