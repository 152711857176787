import { Component, OnInit, Input } from '@angular/core';
import { Employee } from '../../../classes/employee';
import { StringService } from '../../../services/string.service';
import { DateService } from '../../../services/date.service';
import { ConstantsService } from '../../../services/constants.service';
import { IConstants } from '../../../interfaces/i-constants';
import { CompareService } from '../../../services/compare.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  @Input() employee: Employee;
  @Input() fieldsEnabled: boolean;
  public constants: IConstants;

  constructor(public stringsService: StringService,
              public dateService: DateService,
              private constantsService: ConstantsService,
              public compareService: CompareService) {
                this.constants = constantsService.constants;
              }

  ngOnInit() {
    this.constantsService.change.subscribe((constants: IConstants) => { this.constants = constants; })
  }
}
