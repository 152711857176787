import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public APPLICATION_ARTES_GO = "Artes GO";
  public APPLICATION_BROKER = "Broker";
  public PLATFORM = "Browser";
  public LOCATION = environment.apiUrl;

  //AUTH
  public LOGIN = this.LOCATION + "/login";
  public LOGOUT = this.LOCATION + "/logout";
  public VERIFY_SESSION = this.LOCATION + "/verify";
  
  //POST
  public CREATE_PASSWORD = this.LOCATION + "/create-password";
  public REQUEST_NEW_PASSWORD = this.LOCATION + "/request-new-password";
  public CONFIRM_PASSWORD_REQUEST = this.LOCATION + "/verify-mobile-request";
  public CREATE_EMPLOYEE = this.LOCATION + "/employee";
  public UPLOAD_IMAGE = this.LOCATION + "/image";
  public UPDATE_ROLE = this.LOCATION + "/update-role";
  public AWAIT_EXPORT_UNTIL = this.LOCATION + "/await-export-until";

  //GET
  public SEARCH_EMPLOYEES = this.LOCATION + "/search";
  public GET_STRINGS = this.LOCATION + "/strings";
  public GET_CONNECTION_STRINGS = this.LOCATION + "/connection-strings"
  public GET_APPLICATION = this.LOCATION + "/application";
  public GET_EMPLOYEE_BY_ID = this.LOCATION + "/person";
  public GET_CONSTANTS = this.LOCATION + "/constants";
  public GET_PHONE_BOOK_FILE = this.LOCATION + "/phone-book";

  //PUT
  public UPDATE_EMPLOYEE = this.LOCATION + "/employee";

  constructor() { }
}
