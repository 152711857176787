<div class="password-container">
  <img src="{{application['icon_url']}}" id="logo">
  <p id="title">{{stringService.connectionStrings['CONFIRM_REQUEST']}}</p>
  <div class="animation-container" *ngIf="!requestSent">
    <div class="form-container">
        <div style="margin-top:20px;">{{stringService.connectionStrings['CONFIRM_REQUEST_EXPLANATION']}}</div>
        <form (ngSubmit)="confirmRequest()">
            <input type="submit" class="form-submit" value="{{stringService.connectionStrings['CONFIRM']}}">
        </form>
    </div>
    <p class="stamp"><span><img src="assets/img/union-logo.png" style="height:10px;">&nbsp;&nbsp;</span>Powered by union</p>
  </div>

  <div class="animation-container" *ngIf="requestSent">
      <div id="form-container" style="font-size: 14px">
              <p>{{stringService.connectionStrings['REQUEST_CONFIRMED_EXPLANATION']}}</p>
          <input type="submit" class="form-submit" (click)="close()" value="{{stringService.connectionStrings['CLOSE_PAGE']}}">
      </div>
  </div>
</div>
