import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { ApiService } from './api.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  public application: Object = {}

  constructor(private http: HttpClient,
              private headers: HeaderService,
              private api: ApiService) { }

  @Output() change: EventEmitter<any> = new EventEmitter();

  toggle(application: any) {
    this.application = application;
    this.change.emit(application);
  }

  public getApplication(application: string) {
    return this.http.get<any>(this.api.GET_APPLICATION + "/" + application, this.headers.getOptions())
  }
}
