import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AuthenticationService } from 'app/services/authentication.service';
import { catchError, switchMap, filter, take, map } from 'rxjs/operators';
import { StringService } from '../services/string.service';
import { environment } from 'environments/environment';
import { LoginStateService } from '../services/login-state.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshingToken: Boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private auth: AuthenticationService,
              private stringService: StringService,
              private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.auth.getAccessToken()) {
      request = this.addToken(request, this.auth.getAccessToken())
    }
    return next.handle(request).pipe(catchError((error: any): Observable<any> => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.onUnauthorized(request, next)
      } else {
        if (error instanceof HttpErrorResponse && error.url.indexOf(environment.ssoUrl) === -1) {
          if (this.stringService.strings[error.message]) {
            alert(this.stringService.strings[error.message]);
          } else {
            alert(error.message)
          }
        } else if (error instanceof HttpErrorResponse && error.url.indexOf(environment.ssoUrl) !== -1 && error.status === 400) {
          this.auth.requestAuthCode(location.origin + this.router.url)
        }
        return throwError(error); 
      }
    }))
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    })
  }

  private onUnauthorized(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.refreshTokenSubject.next(null);

      return this.auth.refreshAccessToken().pipe(
        switchMap((response: any) => {
          this.isRefreshingToken = false;
          this.refreshTokenSubject.next(response.access_token);
          return next.handle(this.addToken(request, response.access_token))
        })
      );
    } else {
      this.isRefreshingToken = false;
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addToken(request, accessToken))
        })
      )
    }
  }
}
