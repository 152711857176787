import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PasswordService } from '../../services/password.service';
import { StringService } from '../../services/string.service';
import { ApplicationService } from '../../services/application.service';

@Component({
  selector: 'app-request-new-password',
  templateUrl: './request-new-password.component.html',
  styleUrls: ['../new-password/new-password.component.scss']
})
export class RequestNewPasswordComponent implements OnInit {

  public requestNewPasswordForm: FormGroup;
  public requestSent: boolean = false;
  private appName: string;
  public address: string = "";
  public application: Object = {};

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute, 
              private passwordService: PasswordService,
              public stringService: StringService,
              private applicationService: ApplicationService) { 
                this.application = applicationService.application;
              }

  ngOnInit() {
    this.appName = this.route.snapshot.paramMap.get('application');
    if (this.appName !== undefined && this.appName !== null) {
      this.applicationService.getApplication(this.appName).subscribe((response) => { this.application = response; })
    }

    this.requestNewPasswordForm = this.formBuilder.group({
      email: [undefined, [Validators.required, Validators.email]]
    });
  }

  public request(): void {
    if (this.requestNewPasswordForm.get('email').valid) {
      let email: string = this.requestNewPasswordForm.get('email').value;
      this.passwordService.requestNewPassword(this.application['application'], email).subscribe((response: any) => {
        if (response === true) {
          this.requestSent = true;
          this.address = email;
        }
      })
    }
  }

}
