import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applicationFilter'
})
export class ApplicationFilterPipe implements PipeTransform {

  transform(items: any[], filter: string): any {
    if (!items || !filter) {
      return items;
    }
    let filtered: any = items.find((item: any) => { return item.application === filter });
    if (filtered !== undefined && filtered.roles !== undefined) {
      return filtered.roles;
    }
    return items;
  }

}
