import { Injectable } from '@angular/core';
import * as moment from 'moment';

interface Number {
  padLeft(base, chr);
}

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  public format(d: Date, format?: string): string {
    if (!d) return undefined;
    if (format === 'yyyy-mm-dd') {
      return (
        d.getUTCFullYear() + "-" + 
        ("0" + (d.getUTCMonth()+1)).slice(-2) + "-" + 
        ("0" + d.getUTCDate()).slice(-2) 
      );
    } else {
      return (
        d.getUTCFullYear() + "-" + 
        ("0" + (d.getUTCMonth()+1)).slice(-2) + "-" + 
        ("0" + d.getUTCDate()).slice(-2) + " " + 
        ("0" + d.getUTCHours()).slice(-2) + ":" + 
        ("0" + d.getUTCMinutes()).slice(-2) + ":" + 
        ("0" + d.getUTCSeconds()).slice(-2)
      );
    }
  }

  public now() {
    return moment().format("YYYY-MM-DD HH:mm:ss")
  }

  public validate(d: Date) {
    if (d) return d;
    else return;
  }
}
