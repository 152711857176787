<div class="container" style="padding-bottom: 40px;">
    <div style="text-align: center;margin-top: 40px;">
        <mat-form-field>
            <mat-label>{{stringsService.strings['AWAIT_EXPORT_UNTIL']}}</mat-label>
            <input matInput type="date" [disabled]="!interfaceChangesEnabled" [ngModel]="employee.await_export_until | date:'yyyy-MM-dd'" name="employee.await_export_until" (ngModelChange)="employee.await_export_until = dateService.validate($event); setAwaitUntil();">
        </mat-form-field>
    </div>
    <div class="app">
        <div class="app-icon-holder">
            <img class="app-icon" src="assets/img/workday-logo.png">
        </div>
        <div class="connection">
            <div class="connected" *ngIf="employee.import_from_workday()">{{stringsService.strings['CONNECTED_TO_INTERFACE']}} &#10003;</div>
            <div class="not-connected" *ngIf="!employee.import_from_workday()">{{stringsService.strings['NOT_CONNECTED']}} &#10007;</div>
        </div>
        <div class="interface-connection">
            <p class="invalid" style="color: orange;" *ngIf="employee.source_id !== 1">{{stringsService.strings['REQUIRED_WORKDAY_SOURCE_ID']}}</p>
        </div>
        <button *ngIf="!employee.import_from_workday()" id="merge" (click)="mergeEmployee()">{{stringsService.strings['MERGE_WITH_WORKDAY']}}</button>
    </div>
    <div style="clear:both"></div>
    <div class="app">
        <div class="app-icon-holder">
            <img class="app-icon" src="assets/img/ows-logo.png">
        </div>
        <div class="connection">
            <div class="connected" *ngIf="employee.export_to_ows()">{{stringsService.strings['CONNECTED_TO_INTERFACE']}} &#10003;</div>
            <div class="not-connected" *ngIf="!employee.export_to_ows()">{{stringsService.strings['NOT_CONNECTED']}} &#10007;</div>
        </div>
        <div class="interface-connection">
            <p class="valid" *ngIf="employee.current_contract && employee.current_contract.start_date">{{stringsService.strings['CURRENT_CONTRACT']}}</p>
            <p class="invalid" *ngIf="!employee.current_contract || !employee.current_contract.start_date">{{stringsService.strings['CURRENT_CONTRACT']}}</p>
        </div>
    </div>
    <div style="clear:both"></div>
    <div class="app">
        <div class="app-icon-holder">
            <img class="app-icon" src="assets/img/paloma-logo.jpg">
        </div>
        <div class="connection">
            <div class="connected" *ngIf="employee.export_to_paloma()">{{stringsService.strings['CONNECTED_TO_INTERFACE']}} &#10003;</div>
            <div class="not-connected" *ngIf="!employee.export_to_paloma()">{{stringsService.strings['NOT_CONNECTED']}} &#10007;</div>
        </div>
        <div class="interface-connection">
            <p class="valid" *ngIf="employee.current_contract && employee.current_contract.start_date">{{stringsService.strings['CURRENT_CONTRACT']}}</p>
            <p class="invalid" *ngIf="!employee.current_contract || !employee.current_contract.start_date">{{stringsService.strings['CURRENT_CONTRACT']}}</p>
            <p class="valid" *ngIf="employee.license && employee.license.license_number">{{stringsService.strings['LICENSE']}}</p>
            <p class="invalid" *ngIf="!employee.license || !employee.license.license_number">{{stringsService.strings['LICENSE']}}</p>
        </div>
    </div>
    <div style="clear:both"></div>
    <div class="app">
        <div class="app-icon-holder">
            <img class="app-icon" src="assets/img/artes-go-logo.png">
        </div>
        <div class="connection">
            <div class="connected" *ngIf="employee.export_to_artes()">{{stringsService.strings['CONNECTED_TO_INTERFACE']}} &#10003;</div>
            <div class="not-connected" *ngIf="!employee.export_to_artes()">{{stringsService.strings['NOT_CONNECTED']}} &#10007;</div>
        </div>
        <div class="interface-connection">
            <p class="valid" *ngIf="employee.license && employee.license.license_number">{{stringsService.strings['LICENSE']}}</p>
            <p class="invalid" *ngIf="!employee.license || !employee.license.license_number">{{stringsService.strings['LICENSE']}}</p>
            <p class="valid" *ngIf="employee.birth_date">{{stringsService.strings['BIRTH_DATE']}}</p>
            <p class="invalid" *ngIf="!employee.birth_date">{{stringsService.strings['BIRTH_DATE']}}</p>
            <p class="valid" *ngIf="employee.birth_place">{{stringsService.strings['BIRTH_PLACE']}}</p>
            <p class="invalid" *ngIf="!employee.birth_place">{{stringsService.strings['BIRTH_PLACE']}}</p>
            <p class="valid" *ngIf="employee.nationality_id">{{stringsService.strings['NATIONALITY']}}</p>
            <p class="invalid" *ngIf="!employee.nationality_id">{{stringsService.strings['NATIONALITY']}}</p>
            <p class="valid" *ngIf="employee.employer_id">{{stringsService.strings['EMPLOYER']}}</p>
            <p class="invalid" *ngIf="!employee.employer_id">{{stringsService.strings['EMPLOYER']}}</p>
        </div>
    </div>
</div>
