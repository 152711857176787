<div id="image-cropper" class="modal">
    <div class="modal-content">
        <div class="file-upload">
            <input type="file" name="file" id="file" class="inputfile" (change)="fileChangeEvent($event)" />
            <label for="file">Choose a file</label>

            <div id="answer">
                <span id="reject" (click)="imageCropperService.showCropper(false)">&#10007;</span>
                <span *ngIf="imageSelected" id="accept" (click)="upload()">&#10003;</span>
            </div>
        </div>
        <div style="width:500px;height:500px;">
            <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="3 / 4"
            [resizeToWidth]="200"
            [onlyScaleDown]="true"
            [cropperMinHeight]="100"
            [cropperMinWidth]="100"
            format="jpeg"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
        </div>
    </div>
</div>
