<div class="container" style="padding-bottom: 40px;">
    <div class="app">
        <img class="app-icon" src="assets/img/artes-go-logo.png">
        <mat-form-field class="app-access">
            <mat-label>{{stringsService.strings['ROLE']}}</mat-label>
            <mat-select matInput name="employee.artes_role" [(ngModel)]="employee.artes_role" [compareWith]="compareService.ids" (selectionChange)="changeRole(employee.artes_role)" [disabled]="!roleChangesEnabled">
                <mat-option [value]="defaultRole('Artes GO')">{{stringsService.strings['NO_ACCESS']}}</mat-option>
                <mat-option *ngFor="let role of constants.applications | applicationFilter: 'Artes GO'" [value]="role">
                    {{stringsService.strings[role.role]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="app">
        <img class="app-icon" src="assets/img/fejlmelding-logo.png">
        <mat-form-field class="app-access">
            <mat-label>{{stringsService.strings['ROLE']}}</mat-label>
            <mat-select matInput name="employee.fejlmelding_role" [(ngModel)]="employee.fejlmelding_role" [compareWith]="compareService.ids" (selectionChange)="changeRole(employee.fejlmelding_role)" [disabled]="!roleChangesEnabled">
                <mat-option [value]="defaultRole('Fejlmelding')">{{stringsService.strings['NO_ACCESS']}}</mat-option>
                <mat-option *ngFor="let role of constants.applications | applicationFilter: 'Fejlmelding'" [value]="role">
                    {{stringsService.strings[role.role]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="app">
        <img class="app-icon" src="assets/img/tornyster-logo.png">
        <mat-form-field class="app-access">
            <mat-label>{{stringsService.strings['ROLE']}}</mat-label>
            <mat-select matInput name="employee.tornyster_role" [(ngModel)]="employee.tornyster_role" [compareWith]="compareService.ids" (selectionChange)="changeRole(employee.tornyster_role)" [disabled]="!roleChangesEnabled">
                <mat-option [value]="defaultRole('Tornyster')">{{stringsService.strings['NO_ACCESS']}}</mat-option>
                <mat-option *ngFor="let role of constants.applications | applicationFilter: 'Tornyster'" [value]="role">
                    {{stringsService.strings[role.role]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="app">
        <img class="app-icon" src="assets/img/union-logo.png">
        <mat-form-field class="app-access">
            <mat-label>{{stringsService.strings['ROLE']}}</mat-label>
            <mat-select matInput name="employee.broker_role" [(ngModel)]="employee.broker_role" [compareWith]="compareService.ids" (selectionChange)="changeRole(employee.broker_role)" [disabled]="!roleChangesEnabled">
                <mat-option [value]="defaultRole('Broker')">{{stringsService.strings['NO_ACCESS']}}</mat-option>
                <mat-option *ngFor="let role of constants.applications | applicationFilter: 'Broker'" [value]="role">
                    {{stringsService.strings[role.role]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="app">
        <img class="app-icon" src="assets/img/wip-icon.png">
        <mat-form-field class="app-access">
            <mat-label>{{stringsService.strings['ROLE']}}</mat-label>
            <mat-select matInput name="employee.wip_role" [(ngModel)]="employee.wip_role" [compareWith]="compareService.ids" (selectionChange)="changeRole(employee.wip_role)" [disabled]="!roleChangesEnabled">
                <mat-option [value]="defaultRole('WIP')">{{stringsService.strings['NO_ACCESS']}}</mat-option>
                <mat-option *ngFor="let role of constants.applications | applicationFilter: 'WIP'" [value]="role">
                    {{stringsService.strings[role.role]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
