import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginStateService } from '../../services/login-state.service';
import { StringService } from '../../services/string.service';
import { BrokerService } from '../../services/broker.service';
import { saveAs } from 'file-saver';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  public query: string;
  public loading: Boolean = false;

  constructor(
    public loginStateService: LoginStateService,
    public stringService: StringService,
    public router: Router,
    private broker: BrokerService,
    public auth: AuthenticationService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    let code = this.route.snapshot.paramMap.get('code');
    let state = this.route.snapshot.paramMap.get('state');
    if (code && state) {
      try {
        this.loading = true;
        if (state.length % 4 !== 0) {
          for (let i=0; i<state.length % 4; i++) {
            state += '='
          }
        }
        if (state === this.auth.getState()) {
          let savedStateObj = JSON.parse(atob(this.auth.getState()));
          this.auth.requestAccessToken(code, savedStateObj.redirect)
        }
      } catch (e) {
        this.loading = false;
      }
    }
  }

  public generatePhonebook() {
    this.broker.getPhoneBookFile().subscribe((file) => { saveAs(file, 'telefonbog.csv') })
  }

}
