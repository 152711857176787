import { Role } from "./role";

export class Application {

    private _id: number;
    private _application: string;
    private _auto_password_location: string;
    private _manual_password_location: string;
    private _icon_url: string;
    private _roles: Array<Role> = [];

    constructor(obj?: any) {
        this._id = obj.id;
        this._application = obj.application;
        this._auto_password_location = obj.auto_password_location;
        this._manual_password_location = obj.manual_password_location;
        this._icon_url = obj.icon_url;
        if (obj.roles !== undefined) {
            for (let role of obj.roles) {
                this._roles.push(new Role(role));
            }
        }
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter application
     * @return {string}
     */
	public get application(): string {
		return this._application;
	}

    /**
     * Getter auto_password_location
     * @return {string}
     */
	public get auto_password_location(): string {
		return this._auto_password_location;
	}

    /**
     * Getter manual_password_location
     * @return {string}
     */
	public get manual_password_location(): string {
		return this._manual_password_location;
	}

    /**
     * Getter icon_url
     * @return {string}
     */
	public get icon_url(): string {
		return this._icon_url;
	}

    /**
     * Getter roles
     * @return {Array<Role> }
     */
	public get roles(): Array<Role>  {
		return this._roles;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter application
     * @param {string} value
     */
	public set application(value: string) {
		this._application = value;
	}

    /**
     * Setter auto_password_location
     * @param {string} value
     */
	public set auto_password_location(value: string) {
		this._auto_password_location = value;
	}

    /**
     * Setter manual_password_location
     * @param {string} value
     */
	public set manual_password_location(value: string) {
		this._manual_password_location = value;
	}

    /**
     * Setter icon_url
     * @param {string} value
     */
	public set icon_url(value: string) {
		this._icon_url = value;
	}

    /**
     * Setter roles
     * @param {Array<Role> } value
     */
	public set roles(value: Array<Role> ) {
		this._roles = value;
	}

}
