<div class="view-container" *ngIf="loginStateService.loggedIn">
    <div id="search-holder">
        <form (submit)="router.navigate(['/search/' + query])">
            <input type="search" id="search-input" name="query" placeholder="{{stringService.strings['SEARCH']}}" [(ngModel)]="query" />
        </form>
    </div>

    <div id="new-employee-holder">
        <div id="new-employee" (click)="router.navigate(['/employee'])">+</div>
    </div>
    <img *ngIf="loading" src="assets/img/loading.gif" class="loading">    
</div>

<div id="extra-functions-holder" *ngIf="auth.role.role === 'BROKER_HR' || auth.role.role === 'BROKER_WRITE'">
    <div id="generate-phonebook" (click)="generatePhonebook()"><img src="assets/img/phone-book.png" /></div>
</div>
