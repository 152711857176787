import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompareService {

  constructor() { }

  public ids(o1: any, o2: any): boolean {
    if (!o1 || !o2) return false;
    return o1.id === o2.id;
  }
}
