import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap, catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Role } from 'app/classes/role';
import { environment } from 'environments/environment';
import { LoginStateService } from './login-state.service';
import { Router } from '@angular/router';
import { v4 as uuid } from 'uuid'


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public role: Role = new Role();
  private readonly ACCESS_TOKEN = "ACCESS_TOKEN";
  private readonly REFRESH_TOKEN = "REFRESH_TOKEN";
  private readonly STATE = "STATE";

  constructor(private http: HttpClient, 
              private api: ApiService,
              private loginState: LoginStateService,
              private router: Router) { 
  }

  private getAuthOptions() {
    let headers: any = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded')
    return { headers: headers }
  }

  public storeAccessToken(token: string) {
    localStorage.setItem(this.ACCESS_TOKEN, token)
  }
  
  public getAccessToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN)
  }

  public storeRefreshToken(token: string) {
    localStorage.setItem(this.REFRESH_TOKEN, token);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  public storeState(state: string) {
    localStorage.setItem(this.STATE, state);
  }

  public getState(): string {
    return localStorage.getItem(this.STATE);
  }

  private getAuthUri(state: string): string {
    return `${environment.ssoUrl}/authorize?state=${state}&scope=openid&client_id=${environment.clientId}&redirect_uri=${environment.redirectUrl}&response_type=code&token_content_type=jwt`;
  }

  public refreshAccessToken() {
    let params = `refresh_token=${this.getRefreshToken()}&redirect_uri=${environment.redirectUrl}&client_id=${environment.clientId}&grant_type=refresh_token&token_content_type=jwt`;
    return this.http.post<any>(`${environment.ssoUrl}/token`, new Blob([params], { type: 'application/json' }), this.getAuthOptions())
      .pipe(
        tap((response) => {
          if (response && response.access_token) {
            this.storeAccessToken(response.access_token);
            this.loginState.toggleLoggedIn(true);
          }
          if (response && response.refresh_token) {
            this.storeRefreshToken(response.refresh_token);
          }
        })
      )
  }

  public requestAccessToken(code: string, redirect?: string): Promise<any> {
    let params = `code=${code}&redirect_uri=${environment.redirectUrl}&client_id=${environment.clientId}&grant_type=authorization_code&token_content_type=jwt`
    return this.http.post<any>(`${environment.ssoUrl}/token`, new Blob([params], {type: 'application/json'}), this.getAuthOptions())
      .toPromise()
      .then((response) => {
        if (response && response.access_token) {
          this.storeAccessToken(response.access_token);
          this.loginState.toggleLoggedIn(true)
        } else this.loginState.toggleLoggedIn(false);

        if (response && response.refresh_token) this.storeRefreshToken(response.refresh_token);
        if (redirect && redirect.indexOf(window.location.origin) !== -1) {
          this.router.navigateByUrl(redirect.split(window.location.origin)[1])
        }
        return response
      })
  }

  public requestAuthCode(redirect?: string) {
    let state = uuid()
    let stateObj = {
      id: state,
      application: this.api.APPLICATION_BROKER,
      platform: this.api.PLATFORM,
      redirect: redirect
    }
    let encoded = btoa(JSON.stringify(stateObj));
    this.storeState(encoded)
    window.location.href = this.getAuthUri(encoded);
  }

  public logout() {
    this.storeAccessToken(null);
    this.storeRefreshToken(null);
    this.loginState.toggleLoggedIn(false)
    this.router.navigateByUrl('/start');
  }

  public validateSession() {
    let data = { application: this.api.APPLICATION_BROKER, platform: this.api.PLATFORM };
    return this.http.post<any>(this.api.VERIFY_SESSION, data)
    .pipe(map(
      (response) => {
        this.role = new Role(response.role);
        return response;
      }
    ))
  }
}
