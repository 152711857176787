import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordService } from '../../services/password.service';
import { StringService } from '../../services/string.service';
import { ApplicationService } from '../../services/application.service';

const requirements: RegExp[] = [
  /[A-Z]/,
  /[a-z]/,
  /[0-9]/,
  /[!|@|#|$|%|^|&|*|(|)|-|_|\+]/
];

const minPasswordLength: number = 12;

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  private token: string;
  public createNewPasswordForm: FormGroup;
  private appName: string;
  public username: string = "";
  public application: Object = {};
  public requestSent: boolean = false;

  constructor( private route: ActivatedRoute, 
               private router: Router, 
               private formBuilder: FormBuilder,
               private passwordService: PasswordService,
               public stringService: StringService,
               private applicationService: ApplicationService ) { }

  ngOnInit() {
    console.log(this.route.snapshot.paramMap);
    this.token = this.route.snapshot.paramMap.get('token');
    if (this.token === undefined) {
      if (confirm("Der opstod en fejl. Du bliver stillet tilbage til startsiden.")) {
        this.router.navigate(['/']);
      }
    }
    this.appName = this.route.snapshot.paramMap.get('application');
    if (this.appName === undefined) {
      if (confirm("Der opstod en fejl. Du bliver stillet tilbage til startsiden.")) {
        this.router.navigate(['/']);
      }
    }
    this.username = this.route.snapshot.paramMap.get('username');

    this.applicationService.getApplication(this.appName).subscribe((application: Object) => { this.application = application })

    this.createNewPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      passwordAgain: ['', Validators.required]
    });
  }

  get form() { return this.createNewPasswordForm.controls; }

  createNewPassword(): boolean {
    if (!this.form.password.valid || !this.form.passwordAgain.valid) return;
    if (this.form.password.value !== this.form.passwordAgain.value) { alert(this.stringService.connectionStrings['PASSWORDS_NOT_SIMILAR']); return false; }
    if (!this.verifyPasswordIntegrity(this.form.password.value)) { alert(this.stringService.connectionStrings['PASSWORD_DOES_NOT_MEET_REQUIREMENTS']); return false; }
    this.passwordService.createNewPassword(this.token, this.form.password.value, this.application['application'])
      .subscribe(response => { this.requestSent = true })
  }

  close(): void {
    close();
  }

  private verifyPasswordIntegrity(password: string): boolean {
    if (password.length < minPasswordLength) { return false; }
    for (let i=0; i<requirements.length; i++) {
      if (!requirements[i].test(password)) {
        return false;
      }
    }
    return true;
  }
}
