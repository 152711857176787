import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})

export class PasswordService {

  constructor(private http: HttpClient,
              private api: ApiService,
              private headers: HeaderService) { }

  public requestNewPassword(application: string, email: string) {
    let data = { application: application, platform: this.api.PLATFORM, username: email };
    return this.http.post<any>(this.api.REQUEST_NEW_PASSWORD, data, this.headers.getOptions())
  }

  public createNewPassword(token: string, password: string, application: string) {
    let data = { token: token, application: application, platform: this.api.PLATFORM, password: password };
    return this.http.post<any>(this.api.CREATE_PASSWORD, data, this.headers.getOptions())
  }

  public confirmPasswordRequest(token: string) {
    let data = { token: token };
    return this.http.post<any>(this.api.CONFIRM_PASSWORD_REQUEST, data, this.headers.getOptions())
  }
}
