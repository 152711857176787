<div class="password-container">
    <img src="{{application['icon_url']}}" id="logo">
    <p id="title">{{stringService.connectionStrings['CREATE_PASSWORD']}}</p>
    <div class="animation-container" *ngIf="!requestSent">
        <div class="form-container">
            <form [formGroup]="createNewPasswordForm" (ngSubmit)="createNewPassword()" class="form">
                <p>{{username}}</p>
                <input type="password" formControlName="password" name="password" class="form-input" placeholder="{{stringService.connectionStrings['PASSWORD']}}" autofocus>
                <input type="password" formControlName="passwordAgain" class="form-input" placeholder="{{stringService.connectionStrings['RETYPE_PASSWORD']}}">
                <input type="submit" class="form-submit" value="{{stringService.connectionStrings['SEND']}}">
            </form>
        </div>
        
        <p class="stamp"><span><img src="assets/img/union-logo.png" style="height:10px;">&nbsp;&nbsp;</span>Powered by union</p>
    </div>

    <div class="animation-container" *ngIf="requestSent">
        <div id="form-container" style="font-size: 14px">
                <p>{{username}}</p>
                <p>{{stringService.connectionStrings['PASSWORD_WAS_CHANGED']}}</p>
            <input type="submit" class="form-submit" (click)="close()" value="{{stringService.connectionStrings['CLOSE_PAGE']}}">
        </div>
    </div>
</div>

<div id="password-rules">
    <p>{{stringService.connectionStrings['PASSWORD_MINIMUM_REQUIREMENTS']}}:</p>
    <p>
        Min. 12 {{stringService.connectionStrings['CHARACTERS']}}<br>
        Min. 1 {{stringService.connectionStrings['LOWERCASE_LETTER']}}<br>
        Min. 1 {{stringService.connectionStrings['CAPITAL_LETTER']}}<br>
        Min. 1 {{stringService.connectionStrings['DIGIT']}}<br>
        Min. 1 {{stringService.connectionStrings['SPECIAL_CHARACTER']}}<br>
    </p>
</div>