import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from '../../services/password.service';
import { StringService } from '../../services/string.service';
import { BrokerService } from '../../services/broker.service';
import { ApplicationService } from '../../services/application.service';

@Component({
  selector: 'app-confirm-request',
  templateUrl: './confirm-request.component.html',
  styleUrls: ['../new-password/new-password.component.scss']
})
export class ConfirmRequestComponent implements OnInit {

  private token: string;
  public application: Object = {};
  private appName: string;
  public requestSent: boolean = false;

  constructor(private route: ActivatedRoute,
              private passwordService: PasswordService,
              public stringService: StringService,
              private router: Router,
              private applicationService: ApplicationService) { }

  ngOnInit() {
    this.appName = this.route.snapshot.paramMap.get('application');
    if (this.appName === undefined) {
      if (confirm("Der opstod en fejl. Du bliver stillet tilbage til startsiden.")) {
        this.router.navigate(['/']);
      }
    }
    this.applicationService.getApplication(this.appName).subscribe((application) => { this.application = application })
    if (Object.length === 0) {
      this.stringService.getStrings().subscribe((response) => { this.stringService.toggle(response) })
    }
  }

  public confirmRequest() {
    this.token = this.route.snapshot.paramMap.get('token');
    if (this.token !== undefined) {
      this.passwordService.confirmPasswordRequest(this.token).subscribe((response: any) => {
        this.requestSent = true;
      })
    }
  }

  public close() {
    window.close();
  }

}
