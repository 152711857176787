import { Component, OnInit, Input } from '@angular/core';
import { StringService } from '../../../services/string.service';
import { Employee } from '../../../classes/employee';
import { BrokerService } from '../../../services/broker.service';
import { SaveStateService } from '../../../services/save-state.service';
import { DateService } from '../../../services/date.service';

@Component({
  selector: 'app-interfaces',
  templateUrl: './interfaces.component.html',
  styleUrls: ['./interfaces.component.scss']
})
export class InterfacesComponent implements OnInit {

  @Input() employee: Employee;
  @Input() interfaceChangesEnabled: boolean;

  constructor(public stringsService: StringService,
              private brokerService: BrokerService,
              private saveState: SaveStateService,
              public dateService: DateService) { }

  ngOnInit() {
  }

  public mergeEmployee() {
    
  }

  public setAwaitUntil() {
    let message = (this.employee.await_export_until) ? this.stringsService.strings['POSTPONE_EMPLOYEE_EXPORT'] : this.stringsService.strings['REMOVE_EMPLOYEE_EXPORT_POSTPONE'];
    if (confirm(message)) {
      this.saveState.state = false;
      this.brokerService.setAwaitExportUntil(this.employee.id, this.employee.await_export_until.toString()).subscribe(
        () => this.saveState.state = true
      )
    }
  }

}
