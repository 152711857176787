import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { ApiService } from './api.service';
import { Employee } from '../classes/employee';

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  constructor(private http: HttpClient, 
              private headerService: HeaderService,
              private api: ApiService) { 
  }

  public search(query: string) {
    return this.http.get<any>(this.api.SEARCH_EMPLOYEES + "/" + query, this.headerService.getOptions())
  }

  public getPerson(personId: number) {
    return this.http.get<any>(this.api.GET_EMPLOYEE_BY_ID + "/" + personId, this.headerService.getOptions())
  }

  public createPerson(person: Employee) {
    let data = { person: person };
    return this.http.post<any>(this.api.CREATE_EMPLOYEE, data, this.headerService.getOptions())
  }

  public updatePerson(person: Employee) {
    let data = { person: person };
    return this.http.put<any>(this.api.UPDATE_EMPLOYEE, data, this.headerService.getOptions())
  }

  public uploadImage(personId: number, base64: string) {
    let data = { person_id: personId, image: base64 };
    return this.http.post<any>(this.api.UPLOAD_IMAGE, data, this.headerService.getOptions())
  }

  public updateRole(personId: number, applicationId: number, roleId: number) {
    let data = { person_id: personId, application_id: applicationId, role_id: roleId };
    return this.http.post<any>(this.api.UPDATE_ROLE, data, this.headerService.getOptions())
  }

  public setAwaitExportUntil(personId: number, awaitExportUntil: string) {
    let awaitUntil = (!awaitExportUntil) ? null : awaitExportUntil;
    let data = { person_id: personId, await_export_until: awaitUntil };
    return this.http.post<any>(this.api.AWAIT_EXPORT_UNTIL, data, this.headerService.getOptions())
  }

  public getPhoneBookFile() {
    return this.http.get<any>(this.api.GET_PHONE_BOOK_FILE, this.headerService.getFileOptions())
  }
}
