import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SaveStateService {

  public state: boolean = false;

  constructor() { }

  //@Output() stateChange: EventEmitter<string> = new EventEmitter<string>();

  /*public toggle(state: string) {
    this.stateChange.emit(state);
  }*/
}
