export class Role {

    private _id: number;
    private _role: string;
    private _application_id: number;

    constructor(obj?: any) {
        try {
            this._id = obj.id;
            this._role = obj.role;
            this._application_id = obj.application_id;
        } catch (err) {}
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter role
     * @return {string}
     */
	public get role(): string {
		return this._role;
    }
    
    /**
     * Getter application_id
     * @return {number}
     */
	public get application_id(): number {
		return this._application_id;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter role
     * @param {string} value
     */
	public set role(value: string) {
		this._role = value;
    }
    
    /**
     * Setter application_id
     * @param {number} value
     */
	public set application_id(value: number) {
		this._application_id = value;
	}

}
