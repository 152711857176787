import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginStateService } from '../../services/login-state.service';
import { Router, NavigationEnd } from '@angular/router';
import { BrokerService } from '../../services/broker.service';
import { StringService } from '../../services/string.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {

  public people: Array<Object> = [];
  public query: string;
  private routerSub: Subscription;

  constructor(private route: ActivatedRoute,
              private loginStateService: LoginStateService,
              public router: Router,
              private brokerService: BrokerService,
              public stringsService: StringService) { }

  ngOnInit() {
    this.query = this.route.snapshot.paramMap.get('query');
    this.search(this.query);
    if (this.loginStateService.loggedIn) {
      this.routerSub = this.router.events.subscribe(route => {
        if (route instanceof NavigationEnd) {
          let query = this.route.snapshot.paramMap.get('query');
          this.search(query);
        }
      });
    }
  }

  ngOnDestroy() {
    try {
      this.routerSub.unsubscribe();
    } catch (err) {}
    
  }

  private search(query: string) {
    this.brokerService.search(query).subscribe((result: any) => {
      this.people = result;
    })
  }

}
