import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { LoginStateService } from './services/login-state.service';
import { CookieService } from 'ngx-cookie-service';
import { StringService } from './services/string.service';
import { Router, ActivationEnd } from '@angular/router';
import { ConstantsService } from './services/constants.service';
import { ImageCropperService } from './services/image-cropper.service';
import { ActivatedRoute } from '@angular/router';

const noAuthRoutes = ['/request-password', '/create-password', '/confirm-request'];


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: string = 'Broker';
  showLoginScreen: boolean = false;
  showImageCropper: boolean = false;
  constants: Object = {};

  constructor(private auth: AuthenticationService,
              private loginStateService: LoginStateService,
              private cookie: CookieService,
              private stringService: StringService,
              private router: Router,
              private constantsService: ConstantsService,
              private imageCropperService: ImageCropperService,
              private route: ActivatedRoute){}

  ngOnInit() {
    this.validate();
    this.stringService.getStrings(this.cookie.get("language"), true).subscribe((strings: any) => { 
      this.stringService.toggleConnectionStrings(strings); 
    })
    this.stringService.getStrings(this.cookie.get("language")).subscribe((strings: any) => {
      this.stringService.toggle(strings);
    })

    this.imageCropperService.show.subscribe((show: boolean) => { this.showImageCropper = show; })
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (this.auth.getAccessToken()) {
          this.loginStateService.toggleLoggedIn(true)
        } else this.loginStateService.toggleLoggedIn(false)
      }
    })
    this.loginStateService.loggedInChange.subscribe((loggedIn: Boolean) => {
      if (this.router.url.indexOf('/request-password') === 1 &&
          this.router.url.indexOf('/create-password') === 1 && 
          this.router.url.indexOf('/confirm-request') === 1)
      {
        this.showLoginScreen = false;
      } else this.showLoginScreen = !loggedIn;
    })
  }

  private validate(): void {
    this.auth.validateSession().subscribe(
      (data) => {
        if (data && data.person_id) {
          this.constantsService.getConstants().subscribe((constants: any) => { this.constantsService.toggle(constants) })
          this.loginStateService.toggleLoggedIn(true);
        }
      }
    );
  }
}


