import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StringService } from '../../services/string.service';
import { BrokerService } from '../../services/broker.service';
import { ActivatedRoute } from '@angular/router';
import { Employee } from '../../classes/employee';
import { EmploymentConditions } from '../../classes/employment-conditions';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { SaveStateService } from '../../services/save-state.service';
import { DateService } from 'app/services/date.service';


@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  public ROLE_ADMIN = "BROKER_WRITE";
  public ROLE_HR = "BROKER_HR";
  public employee: Employee = new Employee();
  public fieldsEnabled: boolean = false;
  public hasWritePrivileges: boolean = false;
  @Output() saveSuccessChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public mode: string;

  constructor(public stringsService: StringService,
              public broker: BrokerService,
              private route: ActivatedRoute,
              private router: Router,
              public authentication: AuthenticationService,
              public saveState: SaveStateService,
              public dateService: DateService) {}

  ngOnInit() {
    let personId: any = this.route.snapshot.paramMap.get('id');
    if (!isNaN(personId) && personId > 0) {
      this.mode = "UPDATE";
      this.broker.getPerson(personId).subscribe((person: Object) => {
        this.employee = new Employee(person);
        this.fieldsEnabled = !this.employee.import_from_workday() && this.authentication.role.role === this.ROLE_ADMIN;
        this.hasWritePrivileges = this.authentication.role.role === this.ROLE_ADMIN;
      })
    } else {
      this.employee = new Employee({ source_id: 2 });
      this.employee.employment_conditions.push(new EmploymentConditions());
      this.fieldsEnabled = this.authentication.role.role === this.ROLE_ADMIN;
      this.hasWritePrivileges = this.authentication.role.role === this.ROLE_ADMIN;
      this.mode = "CREATE";
    }
  }

  updateEmployee() {
    let question: string = this.stringsService.strings['CHANGES_WILL_BE_SAVED'] + "\n" + this.stringsService.strings['ARE_YOU_SURE'];
    if (this.employee.isValid()) {
      if (confirm(question)) {
        this.saveState.state = false;
        if (this.mode === "UPDATE") {
          this.fieldsEnabled = false;
          this.broker.updatePerson(this.employee)
            .subscribe(
              () => { this.saveState.state = this.fieldsEnabled = !this.employee.import_from_workday(); this.employee.dirty = false; },
              () => { this.fieldsEnabled = !this.employee.import_from_workday() && this.authentication.role.role === this.ROLE_ADMIN;
                      this.hasWritePrivileges = this.authentication.role.role === this.ROLE_ADMIN; }
          );
        } else if (this.mode === "CREATE") {
          this.broker.createPerson(this.employee)
            .subscribe(
              (data: any) => { this.router.navigate(['/employee/' + data.insert_id]) },
              () => { this.fieldsEnabled = !this.employee.import_from_workday() && this.authentication.role.role === this.ROLE_ADMIN;
                      this.hasWritePrivileges = this.authentication.role.role === this.ROLE_ADMIN; }
            );
        }
      }
    } else {
      alert("ONE OR MORE REQUIRED FIELDS NOT FILLED OUT (TEST)");
    }
  }

  resetEmployee() {
    let question: string = this.stringsService.strings['CHANGES_WILL_BE_LOST'] + "\n" + this.stringsService.strings['ARE_YOU_SURE'];
    if (confirm(question)) {
      this.employee = new Employee(this.employee.original);
      if (this.employee.newest_contract === undefined) {
        this.employee.employment_conditions.push(new EmploymentConditions());
      }
    }
  }

  now(): string {
    return this.dateService.format(new Date());
  }
}
