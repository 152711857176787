import { Country } from "./country";
import { Employer } from "./employer";
import { EmploymentConditions } from "./employment-conditions";
import { Gender } from "./gender";
import { Source } from "./source";
import { License } from "./license";
import { SafeUrl } from "@angular/platform-browser";
import { DateService } from '../services/date.service';
import { Role } from "./role";

export class Employee {

    private _id: number;
    private _employee_number: string;
    private _source_id: number;
    private _first_name: string;
    private _last_name: string;
    private _full_name: string;
    private _birth_date: Date;
    private _birth_place: string;
    private _nationality_id: number;
    private _private_address: string;
    private _private_email: string;
    private _private_telephone: string;
    private _private_cellphone: string;
    private _work_address: string;
    private _work_email: string;
    private _work_telephone: string;
    private _work_cellphone: string;
    private _employer_id: number;
    private _gender_id: number;
    private _spouse_name: string;
    private _spouse_cellphone: string;
    private _days_until_delete: number;
    private _hire_date: Date;
    private _psi_id: string;
    private _await_export_until: Date;
    private _planned_deletion: Date;
    private _image: SafeUrl;
    private _nationality: Country;
    private _employer: Employer;
    private _employment_conditions: Array<EmploymentConditions> = [];
    private _gender: Gender;
    private _source: Source;
    private _license: License = new License();
    private _artes_role: Role = new Role();
    private _fejlmelding_role: Role = new Role();
    private _tornyster_role: Role = new Role();
    private _broker_role: Role = new Role();
    private _wip_role: Role = new Role();
    private _dirty: boolean = false;
    private _original: any = {};
    private dateService: DateService = new DateService();

    constructor(obj?: any) {
        console.log(obj)
        if (!obj) return this;
        try {
            this._original = obj;
            this._id = obj.id;
            this._employee_number = obj.employee_number;
            this._source_id = obj.source_id;
            this._first_name = obj.first_name;
            this._last_name = obj.last_name;
            this._full_name = obj.full_name;
            this._birth_date = (obj.birth_date) ? new Date(obj.birth_date) : undefined;
            this._birth_place = obj.birth_place;
            this._nationality_id = obj.nationality_id;
            this._private_address = obj.private_address;
            this._private_email = obj.private_email;
            this._private_telephone = obj.private_telephone;
            this._private_cellphone = obj.private_cellphone;
            this._work_address = obj.work_address;
            this._work_email = obj.work_email;
            this._work_telephone = obj.work_telephone;
            this._work_cellphone = obj.work_cellphone;
            this._employer_id = obj.employer_id;
            this._gender_id = obj.gender_id;
            this._spouse_name = obj.spouse_name;
            this._spouse_cellphone = obj.spouse_cellphone;
            this._days_until_delete = obj.days_until_delete;
            this._hire_date = (obj.hire_date) ? new Date(obj.hire_date) : undefined;
            this._psi_id = obj.psi_id;
            this._await_export_until = (obj.await_export_until) ? new Date(obj.await_export_until): undefined;
            this._image = obj.image;
            this._planned_deletion = (obj.planned_deletion) ? new Date(obj.planned_deletion) : undefined;
            this._nationality = new Country(obj.nationality);
            this._employer = new Employer(obj.employer);
            this._gender = new Gender(obj.gender);
            this._source = new Source(obj.source);
            this._license = new License(obj.license);
            this._artes_role = new Role(obj.artes_role);
            this._fejlmelding_role = new Role(obj.fejlmelding_role);
            this._tornyster_role = new Role(obj.tornyster_role);
            this._broker_role = new Role(obj.broker_role);
            this._wip_role = new Role(obj.wip_role);
            if (obj.employment_conditions !== undefined) {
                for (let c of obj.employment_conditions) {
                    this._employment_conditions.push(new EmploymentConditions(c));
                }
            }
        } catch (err) {
        }
    }

    public toJSON() {
        const employment_conditions: Array<Object> = [];
        for (let c of this._employment_conditions) {
            employment_conditions.push(c.toJSON())
        }
        const birth_date = (!this._birth_date) ? null : this.dateService.format(this._birth_date, 'yyyy-mm-dd');
        const hire_date = (!this._hire_date) ? null : this.dateService.format(this._hire_date, 'yyyy-mm-dd');
        const planned_deletion = (!this._planned_deletion) ? null : this.dateService.format(this._planned_deletion, 'yyyy-mm-dd');
        return {
            id: this._id,
            employee_number: this._employee_number,
            source_id: this._source_id,
            first_name: this._first_name,
            last_name: this._last_name,
            birth_date: birth_date,
            birth_place: this._birth_place,
            nationality_id: this._nationality_id,
            private_address: this._private_address,
            private_email: this._private_email,
            private_telephone: this._private_telephone,
            private_cellphone: this._private_cellphone,
            work_address: this._work_address,
            work_email: this._work_email,
            work_telephone: this._work_telephone,
            work_cellphone: this._work_cellphone,
            employer_id: this._employer_id,
            gender_id: this._gender_id,
            spouse_name: this._spouse_name,
            spouse_cellphone: this._spouse_cellphone,
            hire_date: hire_date,
            psi_id: this._psi_id,
            license: this._license.toJSON(),
            employment_conditions: employment_conditions,
            image: this._image,
            planned_deletion: planned_deletion
        }
    }

    public export_to_artes(): boolean {
        return this.license && this.license.license_number &&
        this.birth_date && this.birth_place && 
        this.nationality_id && this.employer_id !== undefined;
    }

    public import_from_workday(): boolean {
        return this.source_id === 1;
    }

    public export_to_ows(): boolean {
        return this.current_contract !== undefined && this.current_contract.start_date !== undefined;
    }

    public export_to_paloma(): boolean {
        return this.export_to_ows && 
            this.license !== undefined && 
            this.license.license_number !== undefined;
    }

    public export_to_metricus(): boolean {
        return this.employee_number !== undefined;
    }

    public resetRole(appName: string) {
        switch(appName) {
            case "Artes GO":
                this._artes_role = new Role(this._original.artes_role);
                break;
            case "Fejlmelding":
                this._fejlmelding_role = new Role(this._original.fejlmelding_role);
                break;
            case "Tornyster":
                this._tornyster_role = new Role(this._original.tornyster_role);
                break;
            case "Broker":
                this._broker_role = new Role(this._original.broker_role);
                break;
            case "WIP":
                this._wip_role = new Role(this._original.wip_role);
                break;
        }
    }

    public get newest_contract(): EmploymentConditions {
        let newest;
        for (let c of this.employment_conditions) {
            if (!newest || c.start_date > newest.start_date) newest = c;
        }
        return newest;
    }

    public get current_contract(): EmploymentConditions {
        let now = new Date().getTime();
        for (let c of this.employment_conditions) {
            if (c.start_date === undefined || !c.id) return c;
            if (c.end_date === undefined || c.end_date.getTime() > now) {
                return c;
            }
        }
        return;
    }

    public isValid(): boolean {
        return (
            this._first_name !== undefined &&
            this._last_name !== undefined &&
            this._employer_id !== undefined
        )
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter employee_number
     * @return {string}
     */
	public get employee_number(): string {
		return this._employee_number;
	}

    /**
     * Getter source_id
     * @return {number}
     */
	public get source_id(): number {
		return this._source_id;
	}

    /**
     * Getter first_name
     * @return {string}
     */
	public get first_name(): string {
		return this._first_name;
	}

    /**
     * Getter last_name
     * @return {string}
     */
	public get last_name(): string {
		return this._last_name;
	}

    /**
     * Getter full_name
     * @return {string}
     */
	public get full_name(): string {
		return this._full_name;
	}

    /**
     * Getter birth_date
     * @return {Date}
     */
	public get birth_date(): Date {
		return this._birth_date;
	}

    /**
     * Getter birth_place
     * @return {string}
     */
	public get birth_place(): string {
		return this._birth_place;
	}

    /**
     * Getter nationality_id
     * @return {number}
     */
	public get nationality_id(): number {
		return this._nationality_id;
	}

    /**
     * Getter planned_deletion
     * @return {Date}
     */
	public get planned_deletion(): Date {
		return this._planned_deletion;
	}

    /**
     * Getter private_address
     * @return {string}
     */
	public get private_address(): string {
		return this._private_address;
	}

    /**
     * Getter private_email
     * @return {string}
     */
	public get private_email(): string {
		return this._private_email;
	}

    /**
     * Getter private_telephone
     * @return {string}
     */
	public get private_telephone(): string {
		return this._private_telephone;
	}

    /**
     * Getter private_cellphone
     * @return {string}
     */
	public get private_cellphone(): string {
		return this._private_cellphone;
	}

    /**
     * Getter work_address
     * @return {string}
     */
	public get work_address(): string {
		return this._work_address;
	}

    /**
     * Getter work_email
     * @return {string}
     */
	public get work_email(): string {
		return this._work_email;
	}

    /**
     * Getter work_telephone
     * @return {string}
     */
	public get work_telephone(): string {
		return this._work_telephone;
	}

    /**
     * Getter work_cellphone
     * @return {string}
     */
	public get work_cellphone(): string {
		return this._work_cellphone;
	}

    /**
     * Getter employer_id
     * @return {number}
     */
	public get employer_id(): number {
		return this._employer_id;
	}

    /**
     * Getter gender_id
     * @return {number}
     */
	public get gender_id(): number {
		return this._gender_id;
	}

    /**
     * Getter spouse_name
     * @return {string}
     */
	public get spouse_name(): string {
		return this._spouse_name;
	}

    /**
     * Getter spouse_cellphone
     * @return {string}
     */
	public get spouse_cellphone(): string {
		return this._spouse_cellphone;
	}

    /**
     * Getter days_until_delete
     * @return {number}
     */
	public get days_until_delete(): number {
		return this._days_until_delete;
	}

    /**
     * Getter hire_date
     * @return {Date}
     */
	public get hire_date(): Date {
		return this._hire_date;
	}

    /**
     * Getter psi_id
     * @return {string}
     */
	public get psi_id(): string {
		return this._psi_id;
	}

    /**
     * Getter await_export_until
     * @return {Date}
     */
	public get await_export_until(): Date {
		return this._await_export_until;
	}

    /**
     * Getter image
     * @return {SafeUrl}
     */
	public get image(): SafeUrl {
		return this._image;
	}

    /**
     * Getter nationality
     * @return {Country}
     */
	public get nationality(): Country {
		return this._nationality;
	}

    /**
     * Getter employer
     * @return {Employer}
     */
	public get employer(): Employer {
		return this._employer;
	}

    /**
     * Getter employment_conditions
     * @return {Array<EmploymentConditions> }
     */
	public get employment_conditions(): Array<EmploymentConditions>  {
		return this._employment_conditions;
    }

    /**
     * Getter gender
     * @return {Gender}
     */
	public get gender(): Gender {
		return this._gender;
	}

    /**
     * Getter source
     * @return {Source}
     */
	public get source(): Source {
		return this._source;
	}

    /**
     * Getter license
     * @return {License}
     */
	public get license(): License {
		return this._license;
    }

    /**
     * Getter artes_role
     * @return {Role}
     */
	public get artes_role(): Role {
		return this._artes_role;
    }

    /**
     * Getter fejlmelding_role
     * @return {Role}
     */
	public get fejlmelding_role(): Role {
		return this._fejlmelding_role;
    }

    /**
     * Getter tornyster_role
     * @return {Role}
     */
	public get tornyster_role(): Role {
		return this._tornyster_role;
    }

    /**
     * Getter broker_role
     * @return {Role}
     */
	public get broker_role(): Role {
		return this._broker_role;
    }

    /**
     * Getter wip_role
     * @return {Role}
     */
	public get wip_role(): Role {
		return this._wip_role;
    }

    /**
     * Getter original
     * @return {any}
     */
	public get original(): any {
		return this._original;
    }

    /**
     * Getter dirty
     * @return {boolean}
     */
	public get dirty(): boolean {
		return this._dirty;
    }

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
        this._dirty = true;
		this._id = value;
	}

    /**
     * Setter employee_number
     * @param {string} value
     */
	public set employee_number(value: string) {
        this._dirty = true;
		this._employee_number = value;
	}

    /**
     * Setter source_id
     * @param {number} value
     */
	public set source_id(value: number) {
        this._dirty = true;
		this._source_id = value;
	}

    /**
     * Setter first_name
     * @param {string} value
     */
	public set first_name(value: string) {
        this._dirty = true;
		this._first_name = value;
	}

    /**
     * Setter planned_deletion
     * @param {Date} value
     */
	public set planned_deletion(value: Date) {
        this._dirty = true;
		this._planned_deletion = value;
	}

    /**
     * Setter last_name
     * @param {string} value
     */
	public set last_name(value: string) {
        this._dirty = true;
		this._last_name = value;
	}

    /**
     * Setter full_name
     * @param {string} value
     */
	public set full_name(value: string) {
        this._dirty = true;
		this._full_name = value;
	}

    /**
     * Setter birth_date
     * @param {Date} value
     */
	public set birth_date(value: Date) {
        if (typeof value === 'string') { value = new Date(value); }
        this._dirty = true;
		this._birth_date = value;
	}

    /**
     * Setter birth_place
     * @param {string} value
     */
	public set birth_place(value: string) {
        this._dirty = true;
		this._birth_place = value;
	}

    /**
     * Setter nationality_id
     * @param {number} value
     */
	public set nationality_id(value: number) {
        this._dirty = true;
		this._nationality_id = value;
	}

    /**
     * Setter private_address
     * @param {string} value
     */
	public set private_address(value: string) {
        this._dirty = true;
		this._private_address = value;
	}

    /**
     * Setter private_email
     * @param {string} value
     */
	public set private_email(value: string) {
        this._dirty = true;
		this._private_email = value;
	}

    /**
     * Setter private_telephone
     * @param {string} value
     */
	public set private_telephone(value: string) {
        this._dirty = true;
		this._private_telephone = value;
	}

    /**
     * Setter private_cellphone
     * @param {string} value
     */
	public set private_cellphone(value: string) {
        this._dirty = true;
		this._private_cellphone = value;
	}

    /**
     * Setter work_address
     * @param {string} value
     */
	public set work_address(value: string) {
        this._dirty = true;
		this._work_address = value;
	}

    /**
     * Setter work_email
     * @param {string} value
     */
	public set work_email(value: string) {
        this._dirty = true;
		this._work_email = value;
	}

    /**
     * Setter work_telephone
     * @param {string} value
     */
	public set work_telephone(value: string) {
        this._dirty = true;
		this._work_telephone = value;
	}

    /**
     * Setter work_cellphone
     * @param {string} value
     */
	public set work_cellphone(value: string) {
        this._dirty = true;
		this._work_cellphone = value;
	}

    /**
     * Setter employer_id
     * @param {number} value
     */
	public set employer_id(value: number) {
        this._dirty = true;
		this._employer_id = value;
	}

    /**
     * Setter gender_id
     * @param {number} value
     */
	public set gender_id(value: number) {
        this._dirty = true;
		this._gender_id = value;
	}

    /**
     * Setter spouse_name
     * @param {string} value
     */
	public set spouse_name(value: string) {
        this._dirty = true;
		this._spouse_name = value;
	}

    /**
     * Setter spouse_cellphone
     * @param {string} value
     */
	public set spouse_cellphone(value: string) {
        this._dirty = true;
		this._spouse_cellphone = value;
	}

    /**
     * Setter days_until_delete
     * @param {number} value
     */
	public set days_until_delete(value: number) {
        this._dirty = true;
		this._days_until_delete = value;
	}

    /**
     * Setter hire_date
     * @param {Date} value
     */
	public set hire_date(value: Date) {
        if (typeof value === 'string') { value = new Date(value); }
        this._dirty = true;
		this._hire_date = value;
	}

    /**
     * Setter psi_id
     * @param {string} value
     */
	public set psi_id(value: string) {
        this._dirty = true;
		this._psi_id = value;
	}

    /**
     * Setter await_export_until
     * @param {Date} value
     */
	public set await_export_until(value: Date) {
		this._await_export_until = value;
	}

    /**
     * Setter image
     * @param {SafeUrl} value
     */
	public set image(value: SafeUrl) {
        this._image = value;
	}

    /**
     * Setter nationality
     * @param {Country} value
     */
	public set nationality(value: Country) {
        this._dirty = true;
        this._nationality_id = value.id;
		this._nationality = value;
	}

    /**
     * Setter employer
     * @param {Employer} value
     */
	public set employer(value: Employer) {
        this._dirty = true;
        this._employer_id = value.id;
		this._employer = value;
	}

    /**
     * Setter employment_conditions
     * @param {Array<EmploymentConditions> } value
     */
	public set employment_conditions(value: Array<EmploymentConditions> ) {
        this._dirty = true;
		this._employment_conditions = value;
    }

    /**
     * Setter gender
     * @param {Gender} value
     */
	public set gender(value: Gender) {
        this._dirty = true;
        this._gender_id = value.id;
		this._gender = value;
	}

    /**
     * Setter source
     * @param {Source} value
     */
	public set source(value: Source) {
        this._dirty = true;
		this._source = value;
	}

    /**
     * Setter license
     * @param {License} value
     */
	public set license(value: License) {
        this._dirty = true;
		this._license = value;
    }

    /**
     * Setter artes_role
     * @param {Role} value
     */
	public set artes_role(value: Role) {
		this._artes_role = value;
    }

    /**
     * Setter fejlmelding_role
     * @param {Role} value
     */
	public set fejlmelding_role(value: Role) {
		this._fejlmelding_role = value;
    }

    /**
     * Setter tornyster_role
     * @param {Role} value
     */
	public set tornyster_role(value: Role) {
		this._tornyster_role = value;
    }

    /**
     * Setter broker_role
     * @param {Role} value
     */
	public set broker_role(value: Role) {
		this._broker_role = value;
    }

    /**
     * Setter wip_role
     * @param {Role} value
     */
	public set wip_role(value: Role) {
		this._wip_role = value;
    }

    /**
     * Setter original
     * @param {any} value
     */
	public set original(value: any) {
		this._original = value;
    }

    /**
     * Setter dirty
     * @param {boolean} value
     */
	public set dirty(value: boolean) {
        this.newest_contract.dirty = value;
        this._license.dirty = value;
		this._dirty = value;
    }
}
